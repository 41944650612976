import VueRouter, { RouteRecord } from 'vue-router'

interface AclOptions {
  role(): string
  router: VueRouter
}

/* export default new AclCreate({
  initial: 'guest',
  notfound: {
    path: '/error',
    forwardQueryParams: true
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    isPublic: new AclRule('guest')
      .or('superadmin')
      .or('admin')
      .or('ceo')
      .or('accountmanager')
      .or('operationmanager')
      .or('financialmanager')
      .or('auditor')
      .or('agencyhead')
      .or('accounting')
      .generate(),
    isLogged: new AclRule('superadmin')
      .or('admin')
      .or('ceo')
      .or('accountmanager')
      .or('operationmanager')
      .or('financialmanager')
      .or('auditor')
      .or('agencyhead')
      .or('accounting')
      .generate(),

    canCreateUser: new AclRule('superadmin').or('admin').generate(),
    canEditUser: new AclRule('superadmin').or('admin').generate(),
    canDeleteUser: new AclRule('superadmin').generate(),

    canManageDisbursement: new AclRule('superadmin')
      .or('accountmanager')
      .or('financialmanager')
      .generate()
  },

  middleware(acl: any) {
    acl.change(store.getters['auth/userRole'])
  }
}) */

export default {
  install(vue: any, options: AclOptions) {
    const granted = (role: string | string[]) => {
      if (!Array.isArray(role)) {
        role = role.split('|')
      }

      const userRole = options.role()

      return role.length === 0 || role.includes(userRole)
    }

    if (options.router) {
      options.router.beforeEach((to, from, next) => {
        let requiredRoles = []
        const routeWithRole = to.matched.find(
          (route: RouteRecord) => route.meta && route.meta.role
        )

        if (routeWithRole) {
          requiredRoles = routeWithRole.meta.role
        }

        if (granted(requiredRoles)) {
          return next()
        }

        next(false)
      })
    }

    vue.prototype.$granted = granted
  }
}
