var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-x-hidden bg-white rounded",class:{ shadow: !_vm.flat }},[(!_vm.hideTableHeader)?_c('div',{staticClass:"py-3 rounded-t",class:{ 'px-4': !_vm.flat }},[_c('div',{staticClass:"flex flex-wrap items-center justify-between"},[_c('div',[_vm._t("title",function(){return [_c('h3',{staticClass:"text-2xl font-semibold text-primary"},[_vm._v(_vm._s(_vm.title))])]})],2),_c('div',[_vm._t("table-actions",function(){return [_c('div',{staticClass:"flex"},[(!_vm.hideAddAction)?_c('v-button',{staticClass:"mr-4",attrs:{"small":"","type":"button","color":"secondary"},on:{"click":_vm.onAddClicked}},[_c('v-icon',{attrs:{"icon":"add","size":"3","mr":"1"}}),_vm._v(" "+_vm._s(_vm.addButtonText)+" ")],1):_vm._e(),(!_vm.hideSearchInput)?_c('v-input',{staticClass:"w-64",attrs:{"small":"","left-icon":"search","placeholder":"Rechercher..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)]})],2)])]):_vm._e(),_c('div',{staticClass:"block w-full overflow-x-auto",style:(_vm.bodyStyle)},[_c('table',{staticClass:"items-center w-full bg-transparent border-collapse"},[_c('thead',[_c('tr',[(_vm.expandable)?_c('th',{staticClass:"py-3 align-middle bg-gray-100 border border-l-0 border-r-0 border-gray-200 border-solid"}):_vm._e(),_vm._l((_vm.formattedColumns),function(column,i){return _c('th',{key:i,staticClass:"px-3 py-3 text-xs font-semibold text-gray-600 uppercase whitespace-no-wrap align-middle bg-gray-100 border border-l-0 border-r-0 border-gray-200 border-solid cursor-pointer hover:text-gray-900",class:[
              column.class,
              ("text-" + (column.align)),
              {
                'text-gray-900': _vm.sort.field === column.field
              }
            ],style:(_vm.tableHeaderStyle),on:{"click":function($event){return _vm.onColumnClicked(column)}}},[_vm._v(" "+_vm._s(column.label)+" "),(column.sortable)?[_c('span',[(_vm.sort.field === column.field)?_c('v-icon',{attrs:{"icon":_vm.sort.desc ? 'chevron-down' : 'chevron-up',"size":"3"}}):_c('v-icon',{attrs:{"icon":"chevron-up-down","size":"2"}})],1)]:_vm._e()],2)})],2)]),_c('tbody',[(_vm.loading)?_c('tr',[_c('td',{staticClass:"px-3 py-4 text-center",attrs:{"colspan":_vm.columns.length}},[_c('v-spinner',{attrs:{"size":"10"}}),_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.loadingText))])],1)]):(_vm.visibleItems.length)?[_vm._l((_vm.visibleItems),function(item,i){return [_c('tr',{key:i,class:_vm.rowClass(item, i),on:{"mouseenter":function($event){_vm.currentElement = item},"mouseleave":function($event){_vm.currentElement = null}}},[(_vm.expandable)?_c('td',{staticClass:"px-1"},[_c('button',{on:{"click":function($event){return _vm.toggleRowDetails(i)}}},[_c('v-icon',{attrs:{"icon":"chevron-right"}})],1)]):_vm._e(),_vm._l((_vm.columns),function(column,j){return _c('td',{key:j,staticClass:"px-3 py-3 text-xs whitespace-no-wrap align-middle border-b border-gray-200 border-solid last:border-b-0",class:[column.class, ("text-" + (column.align))]},[(
                    column.field == 'action' &&
                      _vm.currentElement == item &&
                      _vm.showHover
                  )?_vm._t("action",function(){return [[_vm._v(_vm._s(_vm.getItemField(item, column.field)))]]},{"item":item,"index":i,"iteration":i + 1}):_vm._e(),(
                    column.field == 'action' &&
                      _vm.showHover &&
                      _vm.currentElement != item
                  )?_c('div',{staticStyle:{"width":"80px"}}):_vm._e(),(column.field != 'action')?_vm._t(column.field,function(){return [(column.currency)?[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.getItemField(item, column.field)))+" ")]:[_vm._v(_vm._s(_vm.getItemField(item, column.field)))]]},{"item":item,"index":i,"iteration":i + 1}):_vm._e()],2)})],2),(_vm.expandable)?_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRowExpanded(i)),expression:"isRowExpanded(i)"}],key:("row-details-" + i),staticClass:"border-b",class:_vm.rowClass(item, i)},[_c('td',{attrs:{"colspan":_vm.columns.length + 1}},[_vm._t("row-details",null,{"item":item,"index":i})],2)]):_vm._e()]})]:_c('tr',[_c('td',{staticClass:"px-3 py-4 align-middle",attrs:{"colspan":_vm.columns.length}},[_vm._t("no-data",function(){return [_c('div',{staticClass:"text-xs text-center"},[_vm._v(_vm._s(_vm.noDataText))])]})],2)]),_vm._t("table-footer")],2)])]),(_vm.paginated && _vm.pages > 1)?_c('div',{staticClass:"flex justify-between",class:{
      'bg-white': _vm.bgWhitePaginated,
      'bg-gray-200': !_vm.bgWhitePaginated
    }},[_c('nav',{staticClass:"pr-2",class:{
        'border-r': !_vm.bgWhitePaginated
      }},[_c('button',{staticClass:"relative inline-flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500",attrs:{"type":"button","aria-label":"Previous"},on:{"click":_vm.onPrevClicked}},[_c('v-icon',{attrs:{"icon":"chevron-left"}})],1),_c('button',{staticClass:"relative inline-flex items-center px-2 py-2 -ml-px text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500",attrs:{"type":"button","aria-label":"Next"},on:{"click":_vm.onNextClicked}},[_c('v-icon',{attrs:{"icon":"chevron-right"}})],1)]),_c('div',{staticClass:"flex items-center px-3 border-l"},[_vm._v(" Ligne "+_vm._s((_vm.page - 1) * _vm.perPage + 1)+" - "+_vm._s(_vm.page * _vm.perPage + 1)+" de "+_vm._s(_vm.total)+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }