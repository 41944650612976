import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'

import { Position, RouteConfig } from 'vue-router/types/router'
import { AuthRoutes } from '@/apps/Auth/routes'
import { FNMRoutes } from '@/apps/FNM/routes'
import { DashboardRoutes } from '@/apps/FNM/routes'
import { ProductRoutes, SFDRoutes } from '@/apps/SFD/routes'
import store from '@/store'
import Product from '@/store/models/Product'
// import User from '@/store/models/User'
// import { ALL_ROLES } from '@/constants'

Vue.use(VueRouter)

const withMeta = (routes: RouteConfig[], meta = {}) => {
  return routes.map(route => {
    route.meta = {
      ...(route.meta && route.meta),
      ...meta
    }

    return route
  })
}

const createRouter = (routes: RouteConfig[] = []) =>
  new VueRouter({
    routes,
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to: Route, from: Route, savedPosition: void | Position) {
      if (to.hash) {
        return { selector: to.hash }
      }
      return savedPosition || { x: 0, y: 0 }
    }
  })

const router = createRouter(withMeta(AuthRoutes, { layout: 'auth' }))

export const replaceRoutes = (routes: RouteConfig[] = []) => {
  const newRouter = createRouter([
    ...routes,
    ...withMeta(AuthRoutes, { layout: 'auth' })
  ])
  ;(router as any).matcher = (newRouter as any).matcher
  ;(router as any).options = (newRouter as any).options
}

export const loadFNMRoutes = () =>
  replaceRoutes(withMeta([...FNMRoutes, ...DashboardRoutes], { layout: 'fnm' }))

export const loadSFDRoutes = () =>
  replaceRoutes(withMeta([...SFDRoutes, ...ProductRoutes], { layout: 'sfd' }))

if (localStorage.getItem('user')) {
  const user = JSON.parse(localStorage.getItem('user') as string)
  if (user.type === 'fnm') {
    loadFNMRoutes()
  } else {
    loadSFDRoutes()
  }
}

/**
 * This guard prevent users from accessing routes they don't have access to
 *
 * By default, all routes requires auth unless disabled explicitly
 *
 * ```js
 * {
 *   meta: {
 *     auth: false, // to disable authentication
 *     auth: 'guest' // to allow access to only unauthenticated users
 *   }
 * }
 * ```
 */
router.beforeEach((to, from, next) => {
  const authenticated = store.getters['auth/authenticated']
  const verified2FA = store.getters['auth/twoFA']

  const routeWithAuth = to.matched.find(
    r => r.meta && typeof r.meta.auth !== 'undefined'
  )

  if (routeWithAuth) {
    if (routeWithAuth.meta.auth === 'guest' && verified2FA) {
      return next('/')
    }
  } else if (!authenticated) {
    return next({ name: 'login', query: { redirect: to.fullPath } })
  } else if (to.name !== '2fa' && !verified2FA) {
    return next({ name: '2fa' })
  }

  if (to.path.startsWith('/dashboard')) {
    //let currentProduct = store.getters['product/getCurrentProduct']
    const productId = to.params.productId || from.params.productId

    if (!productId) return next('/')
    let currentProduct = Product.find(productId)

    if (!currentProduct) {
      Product.api()
        .fetchAll()
        .then(() => {
          currentProduct = Product.find(productId)
          store.dispatch('product/setCurrentProduct', currentProduct)
          if (!currentProduct) return next('/')

          next()
        })
    } else {
      store.dispatch('product/setCurrentProduct', currentProduct)
      next()
    }
  } else next()
})

// router.beforeEach((to, from, next) => {
//   const authenticated = store.getters['auth/authenticated']
//   const user: User = store.getters['auth/user']

//   if (authenticated) {
//     if (to.path === '/') return next()

//     for (const role of ALL_ROLES) {
//       if (user.role === role.field) {
//         if (!to.meta || !to.meta.roles || !to.meta.roles.includes(role.field)) {
//           if (from.name && from.params)
//             return next({ name: from.name, params: from.params })
//           return next(from.path)
//         }

//         next()
//       }
//     }
//   }

//   next()
// })

export default router
