




















import Vue from 'vue'

export default Vue.extend({
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    onClickLi(this: any, i: number) {
      this.items[i].isActive = this.items[i].isActive ? false : true
    }
  }
})
