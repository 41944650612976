




















import Vue from 'vue'

export default Vue.extend({
  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'input'
  },

  props: {
    label: String,
    id: String,
    value: {
      type: String,
      required: true
    },
    checked: String
  },

  computed: {
    isRadioChecked(): boolean {
      return this.checked === this.value
    }
  },

  methods: {
    onChange($event: any) {
      this.$emit('change', $event)
      if ($event.target.checked) {
        this.$emit('input', this.value)
      }
    }
  }
})
