import Vue from 'vue'
import {
  extend,
  setInteractionMode,
  localize,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'
import {
  required,
  email,
  confirmed,
  numeric,
  length,
  min_value as minValue,
  max_value as maxValue,
  max_value as maxValueCustom
} from 'vee-validate/dist/rules'
import fr from './fr.json'

localize('fr', fr)
setInteractionMode('eager')

extend('required', required)
extend('email', email)
extend('confirmed', confirmed)
extend('minValue', minValue)
extend('maxValue', maxValue)
extend('maxValueCustom', maxValueCustom)
extend('numeric', numeric)
extend('length', length)

extend('balanceValue', {
  params: ['bal'],
  validate: (value, { bal }: any) => {
    return value <= bal
  },
  message: 'Fonds insuffisant sur le compte produit'
})

extend('multipleValue', {
  params: ['dividend'],
  validate: (value, { dividend }: any) => {
    return value % dividend == 0
  },
  message: 'La valeur doit être un multiple de {dividend}'
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
