import { Model } from '@vuex-orm/core'
import { Request } from '@vuex-orm/plugin-axios'
import store from '../../store'

import CreditLineDeadline from './CreditLineDeadline'
import CreditLineProvision from './CreditLineProvision'
import Organization from './Organization'
import Product from './Product'
import User from './User'

export enum CreditLineState {
  CREATED = 'created',
  VALIDATED = 'validated',
  COMPLETED = 'completed', // Used when all credits are successful
  COMPLETED_WITH_FAILURE = 'completed_with_failure' // Used when some credits failed
}
export default class CreditLine extends Model {
  static entity = 'credit_lines'

  id!: string
  state!: string
  name!: string
  reference!: string
  mtnAmountReserved!: number
  moovAmountReserved!: number
  totalAmountReserved!: number
  mtnAmount!: number
  moovAmount!: number
  totalAmount!: number
  annualRate!: number
  deferred!: number
  graceDelay!: number
  effectDate!: Date
  durationInMonth!: number
  organizationId!: string
  createdAt!: string

  deadlines: CreditLineDeadline[] = []
  provisions: CreditLineProvision[] = []

  static fields() {
    return {
      id: this.string(''),
      state: this.string(CreditLineState.CREATED),
      name: this.string(''),
      reference: this.string(''),
      mtnAmountReserved: this.number(0),
      moovAmountReserved: this.number(0),
      totalAmountReserved: this.number(0),
      mtnAmount: this.number(0),
      moovAmount: this.number(0),
      totalAmount: this.number(0),
      annualRate: this.number(0),
      deferred: this.number(0),
      graceDelay: this.number(0),
      effectDate: this.attr(''),
      durationInMonth: this.number(0),
      createdById: this.string(''),
      validatedById: this.string(''),
      organizationId: this.string(''),
      productId: this.string(''),
      createdAt: this.string(''),

      createdBy: this.belongsTo(User, 'createdById'),
      validatedBy: this.belongsTo(User, 'validatedById'),
      organization: this.belongsTo(Organization, 'organizationId'),
      product: this.belongsTo(Product, 'productId'),
      provisions: this.hasMany(CreditLineProvision, 'creditLineId'),
      deadlines: this.hasMany(CreditLineDeadline, 'creditLineId')
    }
  }

  get creationTimestamp(): number {
    return new Date(this.createdAt).getTime()
  }

  get mtnProvisionAmount(): number {
    const provision = this.provisions.find(p => p.otm === 'mtn')
    return provision ? provision.amount : 0
  }

  get moovProvisionAmount(): number {
    const provision = this.provisions.find(p => p.otm === 'moov')
    return provision ? provision.amount : 0
  }

  get totalProvisionAmount(): number {
    return this.mtnProvisionAmount + this.moovProvisionAmount
  }

  get isNew(): boolean {
    return this.state === CreditLineState.CREATED
  }

  get validated(): boolean {
    return this.state !== CreditLineState.CREATED
  }

  get completed(): boolean {
    return this.state === CreditLineState.COMPLETED
  }

  get completedWithFailure(): boolean {
    return this.state === CreditLineState.COMPLETED_WITH_FAILURE
  }

  static getCompletedForCurrentOrganization() {
    const currentOrganizationId = this.store().getters['auth/user']
      .organizationId
    const product = store.getters['product/getCurrentProduct']
    return this.query()
      .where('organizationId', currentOrganizationId)
      .where('productId', product.id)
      .where('state', 'completed')
      .where('totalAmount', (value: number) => value > 0)
      .get()
  }

  static getForCurrentOrganization() {
    const currentOrganizationId = this.store().getters['auth/user']
      .organizationId
    const product = store.getters['product/getCurrentProduct']
    return this.query()
      .where('organizationId', currentOrganizationId)
      .where('productId', product.id)
      .where('state', 'completed')
      .whereHas('deadlines', q => {
        q.where('state', 'pending')
      })
      .with('deadlines')
      .get()
  }

  static apiConfig = {
    actions: {
      fetchAll(this: Request) {
        const product = store.getters['product/getCurrentProduct']
        return this.get(`/credit-lines`, {
          params: {
            productId: product.id,
            sort: '-createdAt',
            'state!': 'canceled',
            populate: ['createdBy', 'validatedBy', 'provisions'].join(',')
          }
        })
      },

      fetchById(
        this: Request,
        id: string,
        params = {
          populate: ['deadlines', 'organization', 'provisions']
        }
      ) {
        return this.get(`/credit-lines/${id}`, {
          params: {
            populate: params.populate.join(',')
          }
        })
      },

      create(this: Request, data: any) {
        const product = store.getters['product/getCurrentProduct']
        data['productId'] = product.id
        return this.post('/credit-lines', data)
      },

      validate(this: Request, creditLineId: string, otp?: string) {
        const data = otp ? { otp } : {}
        return this.put(`/credit-lines/${creditLineId}/validate`, data)
      },

      fetchForCurrentOrganization(
        this: Request,
        options: { withDeadline: boolean } = {
          withDeadline: false
        }
      ) {
        const product = store.getters['product/getCurrentProduct']
        const currentOrganizationId = this.model.store().getters['auth/user']
          .organizationId

        const params: Record<string, unknown> = {
          organizationId: currentOrganizationId,
          state: 'completed',
          productId: product.id
        }

        if (options.withDeadline) {
          params.populate = 'deadlines'
        }

        return this.get('/credit-lines', {
          params
        })
      },

      sfdTotalCreditAmount(this: Request, productId: string | null) {
        if (productId)
          return this.get('/credit-lines/sfd-credit-amount/', {
            params: {
              productId: productId
            },
            save: false
          })
        return this.get('/credit-lines/sfd-credit-amount/', {
          save: false
        })
      }
    }
  }
}
