


































































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    flattenedMenu(this: any) {
      const menu: any[] = []

      function scanRoutes(routes: any[], granted: Function) {
        routes
          .filter(r => r.sidebar && granted(r.meta?.role || []))
          .forEach(route => {
            if (route.children && route.sidebar.ignoreChildren !== true) {
              if (route.sidebar.text) {
                menu.push({
                  text: route.sidebar.text,
                  header: true
                })
              }

              scanRoutes(route.children, granted)
            } else {
              if (route.sidebar.separator) {
                menu.push({
                  separator: true
                })
              }

              menu.push({
                text: route.sidebar?.text,
                icon: route.sidebar?.icon,
                iconSize: route.sidebar?.iconSize || 4,
                route: route.name || route.children[0].name
              })
            }
          })
      }

      scanRoutes(this.menu, this.$granted)
      return menu
    }
  }
})
