














































import Vue from 'vue'

import LogoFnm from '@/assets/img/logo-fnm.svg?inline'
import LoginIllustration from '@/assets/img/login-illustration.svg?inline'

export default Vue.extend({
  components: {
    LogoFnm,
    LoginIllustration
  },

  computed: {
    showBackLink() {
      return this.$route.name !== 'login'
    }
  }
})
