import { ActionContext } from 'vuex'
import { IDispatchingFileState } from './state'
import { IRootState } from '../root'
import { Types } from './mutations'

export interface IDispatchingFileActions {
  updateNewDispatchingFile(
    context: ActionContext<IDispatchingFileState, IRootState>,
    values: {
      file: File
      headers: string[]
      items: any[][]
      creditLineId: string
    }
  ): void

  updateNewDispatchingFileMapping(
    context: ActionContext<IDispatchingFileState, IRootState>,
    mapping: Record<string, number>
  ): void
}

export const DispatchingFileActions: IDispatchingFileActions = {
  updateNewDispatchingFile({ commit }, values): void {
    commit(Types.UPDATE_NEW_DISPATCHING_FILE, values)
  },

  updateNewDispatchingFileMapping({ commit }, mapping) {
    commit(Types.UPDATE_NEW_DISPATCHING_FILE_MAPPING, mapping)
  }
}
