import api, { ApiResponse } from '@/api'
import { ActionContext } from 'vuex'
import { IAuthState } from './state'
import { Types } from './mutations'
import { AUTH_ACCESS_TOKEN_KEY } from '@/constants'
import User from '@/store/models/User'
import { IRootState } from '../root'

export interface ILoginCredentials {
  email: string
  password: string
}

export interface IAuthActions {
  check(context: ActionContext<IAuthState, IRootState>): any

  login(
    context: ActionContext<IAuthState, IRootState>,
    credentials: ILoginCredentials
  ): Promise<ApiResponse>

  verify2FA(
    context: ActionContext<IAuthState, IRootState>,
    code: string
  ): Promise<ApiResponse>

  logout(context: ActionContext<IAuthState, IRootState>): void
}

export const AuthActions: IAuthActions = {
  check({ commit, dispatch }: ActionContext<IAuthState, IRootState>) {
    const params = new URLSearchParams(window.location.search)

    if (params.has('t')) {
      const token = params.get('t')
      sessionStorage.setItem(AUTH_ACCESS_TOKEN_KEY, token as string)
      sessionStorage.setItem('fnm.auth.from_v2', 'true')
    }

    const accessToken = sessionStorage.getItem(AUTH_ACCESS_TOKEN_KEY)
    const fromV2 = sessionStorage.getItem('fnm.auth.from_v2')

    if (accessToken) {
      commit(Types.ACCESS_TOKEN, accessToken)
      api.setAccessToken(accessToken, fromV2 === 'true')

      return api
        .getUserInfos(fromV2 === 'true' ? 'v2' : '')
        .then((response: ApiResponse) => {
          if (response.isSuccessful) {
            const user = response.data
            commit(Types.USER_ID, user.id)
            commit(Types.TWO_FA, true)
            User.dispatch('insertOrUpdate', { data: user })
          } else {
            dispatch('logout')
          }

          return response
        })
    }
  },

  login(
    { commit }: ActionContext<IAuthState, IRootState>,
    { email, password }: ILoginCredentials
  ): Promise<ApiResponse> {
    return api
      .authenticate({ email, password })
      .then((response: ApiResponse) => {
        if (response.isSuccessful) {
          const { token, user } = response.data

          commit(Types.USER_ID, user.id)
          commit(Types.ACCESS_TOKEN, token)
          User.dispatch('insertOrUpdate', { data: user })

          api.setAccessToken(token, false)
          sessionStorage.setItem(AUTH_ACCESS_TOKEN_KEY, token)
        }

        return response
      })
  },

  verify2FA({ commit }, code) {
    return api.verifyTwoFactorOtp(code).then((response: ApiResponse) => {
      if (response.isSuccessful) {
        commit(Types.TWO_FA, response.data['2fa'])
      }

      return response
    })
  },

  logout({ commit }: ActionContext<IAuthState, IRootState>) {
    commit(Types.ACCESS_TOKEN, null)
    commit(Types.USER_ID, null)
    api.setAccessToken(null, false)
    sessionStorage.clear()
  }
}
