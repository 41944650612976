








import Vue from 'vue'
import AuthLayout from '@/layouts/auth.vue'
import SfdLayout from '@/layouts/sfd.vue'
import FnmLayout from '@/layouts/fnm.vue'

export default Vue.extend({
  components: {
    AuthLayout,
    SfdLayout,
    FnmLayout
  },

  computed: {
    pageLayout(this: any) {
      const routeWithLayout = this.$route.matched.find(
        (r: any) => r.meta.layout
      )
      const layoutName = routeWithLayout
        ? routeWithLayout.meta.layout
        : undefined

      return layoutName ? `${layoutName}-layout` : 'div'
    }
  }
})
