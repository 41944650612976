













import Vue from 'vue'
import { FNMRoutes, DashboardRoutes } from '@/apps/FNM/routes'
import LogoFnm from '@/assets/img/logo-fnm.svg?inline'

import DashboardLayout from './dashboard.vue'

export default Vue.extend({
  components: {
    LogoFnm,
    DashboardLayout
  },

  data() {
    return {
      routesListe: DashboardRoutes,
      routes: FNMRoutes
    }
  },
  computed: {
    getRoutesListe() {
      if (this.$route) {
        if (this.$route.meta?.dashboard) {
          return DashboardRoutes
        }
      }
      return FNMRoutes
    }
  }
})
