import { NewDispatchingFile, IDispatchingFileState } from './state'

export interface IDispatchingFileGetters {
  newDispatchingFile(state: IDispatchingFileState): NewDispatchingFile
}

export const DispatchingFileGetters: IDispatchingFileGetters = {
  newDispatchingFile(state) {
    return state.newDispatchingFile
  }
}
