


















































import Vue from 'vue'
import getValue from 'lodash/get'

export default Vue.extend({
  props: {
    placeholder: {
      type: String,
      required: false
    },

    items: {
      type: Array,
      default: () => []
    },

    value: {
      type: [String, Number, Object],
      required: false
    },

    itemText: {
      type: String,
      default: 'text'
    },

    itemValue: {
      type: String,
      default: 'value'
    },

    itemClass: {
      type: String,
      required: false
    },

    selectReadonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      expanded: false,
      selectedIndex: -1
    }
  },

  computed: {
    displayedText(this: any) {
      return this.selectedIndex !== -1
        ? this.getItemText(this.items[this.selectedIndex])
        : ''
    }
  },

  watch: {
    value(newValue) {
      this.onValueChanged(newValue)
    },
    expanded(newValue) {
      if (this.selectReadonly) {
        if (newValue) {
          this.expanded = false
        }
      }
    }
  },

  created(this: any) {
    if (this.value !== undefined) {
      this.onValueChanged(this.value)
    }
  },

  methods: {
    getItemText(this: any, item: any) {
      return typeof item === 'string' ? item : getValue(item, this.itemText)
    },

    getItemValue(this: any, item: any) {
      return typeof item === 'string'
        ? item
        : this.itemValue
        ? getValue(item, this.itemValue)
        : item
    },

    onValueChanged(this: any, value: any) {
      this.selectedIndex = this.items.findIndex(
        (item: any) => this.getItemValue(item) === value
      )
    },

    onPlaceholderClick(this: any) {
      this.selectedIndex = -1
      this.$refs.popover.toggle()

      this.$emit('input', '')
    },

    onItemClick(this: any, index: number) {
      const selectedItem = this.items[index]

      this.selectedIndex = index
      this.$refs.popover.toggle()

      this.$emit('input', this.getItemValue(selectedItem))
      this.$emit('select', selectedItem)
    }
  }
})
