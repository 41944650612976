import Vue from 'vue'
import moment from 'moment'

Vue.filter('date', (date: any, format: any = 'DD/MM/YYYY') =>
  moment(date).format(format)
)

Vue.filter('number', (value: any) =>
  new Intl.NumberFormat('fr-FR').format(value)
)

Vue.filter('currency', (value: any) =>
  new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(
    value
  )
)
