import { Model } from '@vuex-orm/core'
import Transaction from './Transaction'
import Beneficiary from './Beneficiary'
import Product from './Product'
import store from '..'

export default class Reimbursement extends Model {
  static entity = 'reimbursements'

  id!: string
  state!: string
  amount!: number
  payerPhone!: string
  beneficiaryId!: string
  transactionId!: string
  beneficiaryGroupId!: string
  reimbursementDeadlineId!: string
  productId!: string

  static fields() {
    return {
      id: this.string(''),
      state: this.string('pending'),
      amount: this.number(0),
      payerPhone: this.string(''),
      beneficiaryId: this.string(''),
      transactionId: this.string(''),
      beneficiaryGroupId: this.string(''),
      reimbursementDeadlineId: this.string(''),
      productId: this.string(''),
      product: this.belongsTo(Product, 'productId'),

      beneficiary: this.belongsTo(Beneficiary, 'beneficiaryId'),
      transaction: this.belongsTo(Transaction, 'transactionId')
    }
  }

  static apiConfig = {
    actions: {
      fetchByBeneficiaryGroup(this: any, groupId: string) {
        const product = store.getters['product/getCurrentProduct']
        return this.get('/reimbursements', {
          params: {
            beneficiaryGroupId: groupId,
            populate: 'transaction,beneficiary',
            sort: 'createdAt',
            productId: product.id
          }
        })
      },
      reimbursementRate(this: any, productId: string) {
        return this.get('/reimbursements/stats/', {
          params: {
            productId: productId
          },
          save: false
        })
      }
    }
  }
}
