import { Model } from '@vuex-orm/core'
import User from './User'
import Organization from './Organization'
import CreditLine from './CreditLine'
import moment from 'moment'
import Transaction from './Transaction'
import { Request } from '@vuex-orm/plugin-axios'
import Product from './Product'
import store from '../../store'

export default class CreditLineDeadline extends Model {
  static entity = 'credit_line_deadlines'

  id!: string
  otm!: string
  date!: string
  state!: string
  capital!: number
  interest!: number
  monthlyAmount!: number
  refundTransactionId!: string
  refundTransaction!: Transaction
  createdAt!: string
  updatedAt!: string
  paidAt!: string
  productId!: string

  static fields() {
    return {
      id: this.string(''),
      otm: this.string(''),
      state: this.string(''),
      date: this.string(''),
      capital: this.number(0),
      interest: this.number(0),
      monthlyAmount: this.number(0),
      createdAt: this.string(''),
      updatedAt: this.string(''),
      paidAt: this.string(''),

      paidById: this.string(null),
      paidBy: this.belongsTo(User, 'paidById'),

      organizationId: this.string(null),
      organization: this.belongsTo(Organization, 'organizationId'),

      creditLineId: this.string(null),
      creditLine: this.belongsTo(CreditLine, 'creditLineId'),

      refundTransactionId: this.string(null),
      refundTransaction: this.belongsTo(Transaction, 'refundTransactionId'),

      productId: this.string(''),
      product: this.belongsTo(Product, 'productId')
    }
  }

  static forCurrentOrganization() {
    const currentOrganizationId = this.store().getters['auth/user']
      .organizationId
    const product = store.getters['product/getCurrentProduct']
    return this.query()
      .where('organizationId', currentOrganizationId)
      .where('productId', product.id)
      .withAll()
      .get()
  }

  static paidForCurrentOrganization() {
    const currentOrganizationId = this.store().getters['auth/user']
      .organizationId
    const product = store.getters['product/getCurrentProduct']
    return this.query()
      .where('organizationId', currentOrganizationId)
      .where('productId', product.id)
      .where('state', 'paid')
      .withAll()
      .get()
  }

  get timestamp() {
    return moment(this.date).unix()
  }

  get dateDay() {
    return moment(this.date).format('YYYY-MM-DD')
  }

  static apiConfig = {
    actions: {
      fetchAll(
        this: Request,
        { includes }: { includes: string[] } = { includes: [] }
      ) {
        const product = store.getters['product/getCurrentProduct']

        return this.get('/credit-line-deadlines', {
          params: {
            productId: product.id,
            populate: includes.join(',')
          }
        })
      },

      forCurrentOrganization(this: Request) {
        const currentOrganizationId = this.model.store().getters['auth/user']
          .organizationId
        const product = store.getters['product/getCurrentProduct']

        return this.get('/credit-line-deadlines', {
          params: {
            productId: product.id,
            organizationId: currentOrganizationId,
            populate: 'creditLine,refundTransaction'
          }
        })
      },

      refund(this: any, id: string, otp?: string) {
        const data = otp ? { otp } : {}
        return this.post(`/credit-line-deadlines/${id}/refund`, data)
      },

      validateRefund(this: any, id: string, otp: string) {
        return this.post(`/credit-line-deadlines/${id}/validate-refund`, {
          otp
        })
      }
    }
  }
}
