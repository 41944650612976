


















import Vue from 'vue'

const VARIANT_STYLES: any = {
  info: 'bg-blue-100 border-blue-300 text-blue-300',
  success: 'bg-green-100 border-green-300 text-green-300',
  warning: 'bg-orange-100 border-orange-300 text-orange-300',
  error: 'bg-red-100 border-red-300 text-red-300'
}

const ICONS: any = {
  info: 'info',
  success: '_check-circle',
  warning: '_warning-2',
  error: 'error'
}

export default Vue.extend({
  props: {
    variant: {
      type: String,
      default: 'info',
      validator: value =>
        /primary|secondary|success|warning|error|info/.test(value)
    },

    title: String,

    message: String
  },

  computed: {
    styleProps(this: any) {
      return VARIANT_STYLES[this.variant]
    },

    icon(this: any) {
      return ICONS[this.variant]
    }
  }
})
