import Axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
  AxiosInstance
} from 'axios'
import store from '@/store'
import router from '@/router'
import fileDownload from 'js-file-download'
import moment from 'moment'
import Product from '@/store/models/Product'

export interface ApiResponse {
  headers: Record<string, string>
  status: number
  data: any // eslint-disable-line
  isOk: boolean
  isSuccessful: boolean
  isNotFound: boolean
  isClientError: boolean
  isBadRequest: boolean
}

export interface Api extends AxiosInstance {
  makeRequest(config: AxiosRequestConfig): Promise<ApiResponse>
  setAccessToken(token: string | null, fromV2: boolean): void
  authenticate(options: {
    email: string
    password: string
  }): Promise<ApiResponse>
  getUserInfos(referer: string): Promise<ApiResponse>

  verifyTwoFactorOtp(otp: string): Promise<ApiResponse>

  requestPasswordReset(data: any): Promise<ApiResponse>
  verifyPasswordResetToken(token: string, email: string): Promise<ApiResponse>
  resetPassword(data: any): Promise<ApiResponse>

  countBeneficiaries(): Promise<ApiResponse>

  creditOrganizationAccount(data: {
    organizationId: string
    //accountType: 'default' | 'refund' | 'interest'
    otm: string
    amount: number
    phone: string
    productId: string
  }): Promise<ApiResponse>

  transactionById(id: string): Promise<ApiResponse>

  searchBeneficiarie(data: string): Promise<ApiResponse>
  allBeneficiarie(data: string): Promise<ApiResponse>
  infoBeneficiarie(data: string): Promise<ApiResponse>

  beneficiaryKyc(phone: string): Promise<ApiResponse>

  download(
    url: string,
    params: Record<string, string>,
    filename?: string
  ): Promise<ApiResponse>

  creditLineDeadlineMetrics(params: {
    dateFrom: Date
    dateTo: Date
    type: string
    organizationId: string
  }): Promise<ApiResponse>

  createCategorie(data: { name: string; code: string }): Promise<ApiResponse>
}

const api: AxiosInstance = Axios.create({
  baseURL: process.env.VUE_APP_API_PATH || '/api/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const wrapResponse = (response: AxiosResponse): ApiResponse => ({
  status: response.status,
  data: response.data,
  isOk: response.status === 200,
  isBadRequest: response.status === 400,
  isNotFound: response.status === 404,
  isSuccessful: response.status >= 200 && response.status < 300,
  isClientError: response.status >= 400 && response.status < 500,
  headers: response.headers
})

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const currentProduct: Product = store.getters['product/getCurrentProduct']
    const currentRoute = router.currentRoute
    console.log({ currentProduct, currentRoute })
    if (!currentRoute.meta?.dashboard && currentProduct.id) {
      config.headers['ProductId'] = currentProduct.id
    }
    return config
  },
  function(error) {
    console.log(error)
  }
)
api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !/login/.test(window.location.href)
    ) {
      console.log('-->', error, error.response)
      store.dispatch('auth/logout').then(() => {
        window.location.href =
          '/login?reason=session-expired&redirect=' + window.location.pathname
      })
    } else {
      return Promise.reject(error)
    }
  }
)

const request = (options: AxiosRequestConfig) =>
    api
      .request(options)
      .then((response: AxiosResponse) => wrapResponse(response))
      .catch((error: AxiosError) => {
        if (error.response) {
          return wrapResponse(error.response)
        }

        throw error
      })

  /**
   * Set auth access token
   * @param token
   */
;(api as Api).setAccessToken = (token: string, fromV2) => {
  api.defaults.headers['Authorization'] = `Bearer ${token}`
  if (fromV2) {
    api.defaults.params = {
      referer: 'v2'
    }
  }
}

/** */
;(api as Api).makeRequest = request

/**
 * Authenticate user
 * @param credentials
 */
;(api as Api).authenticate = (credentials: {
  email: string
  password: string
}) =>
  request({
    method: 'POST',
    url: '/auth/login',
    data: {
      ...credentials
    }
  })

/**
 * Verify Two Factor Auth OTP
 */
;(api as Api).verifyTwoFactorOtp = (otp: string) =>
  request({
    method: 'POST',
    url: '/auth/verify-two-factor',
    data: { otp }
  })

/**
 * Get authenticiated user infos
 */
;(api as Api).getUserInfos = (referer = '') =>
  request({
    method: 'GET',
    url: '/auth/me',
    params: {
      referer
    }
  })

/**
 * Request password reset token
 */
;(api as Api).requestPasswordReset = (data: any) =>
  request({
    method: 'POST',
    url: '/passwords/request',
    data
  })

/**
 *
 */
;(api as Api).verifyPasswordResetToken = (token, email) =>
  request({
    method: 'GET',
    url: '/passwords/check-token',
    params: {
      token,
      email
    }
  })

/**
 *
 */
;(api as Api).resetPassword = (data: any) =>
  request({
    url: '/passwords/reset',
    method: 'PUT',
    data
  })

/**
 * Count beneficiaries
 */
;(api as Api).countBeneficiaries = () =>
  request({
    method: 'GET',
    url: '/beneficiaries/count'
  })

/**
 * Get Transaction By Id
 * @param id
 */
;(api as Api).transactionById = (id: string) =>
  request({
    url: `/transactions/${id}`,
    method: 'GET'
  })

/**
 * Create Categories
 * @param data
 */
;(api as Api).createCategorie = data =>
  request({
    method: 'POST',
    url: `/categories/`,
    data
  })

/**
 * Search beneficiaries
 * @param data
 */
;(api as Api).searchBeneficiarie = (data: string) =>
  request({
    url: `/beneficiaries/research/?search=${data}`,
    method: 'GET'
  })

/**
 * All beneficiaries
 * @param data
 */
;(api as Api).allBeneficiarie = (data: string) =>
  request({
    url: `/beneficiaries/research/?search=${data}`,
    method: 'GET'
  })
/**
 *  Beneficiaries informations
 * @param data
 */
;(api as Api).infoBeneficiarie = (data: string) =>
  request({
    url: `/beneficiaries/client/${data}`,
    method: 'GET'
  })

/**
 *
 * @param param0
 */
;(api as Api).creditOrganizationAccount = data =>
  request({
    method: 'POST',
    url: `/transactions/credit-account`,
    data
  })

/**
 * Check KYC
 */
;(api as Api).beneficiaryKyc = (phone: string) =>
  request({
    method: 'GET',
    url: '/kkiapay/check-recipient',
    params: {
      phone
    }
  })

/**
 *
 */
;(api as Api).download = (url, params, filename = 'report.csv') =>
  request({
    method: 'GET',
    url,
    params,
    responseType: 'blob'
  }).then((response: ApiResponse) => {
    if (response.isSuccessful) {
      fileDownload(
        response.data,
        filename,
        response.headers['content-type'] || 'text/csv'
      )
    }
    return response
  })

/**
 *
 */
;(api as Api).creditLineDeadlineMetrics = params =>
  request({
    method: 'GET',
    url: '/credit-line-deadlines/metrics',
    params: {
      ...params,
      dateFrom: moment(params.dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(params.dateTo).format('YYYY-MM-DD')
    }
  })

export default api as Api
