











import Vue from 'vue'

export default Vue.extend({
  inheritAttrs: false,

  props: {},

  data() {
    return {
      opened: false
    }
  },

  computed: {},

  watch: {
    opened(val) {
      this.$emit(val ? 'open' : 'close')
    }
  },

  mounted() {
    document.body.addEventListener(
      'click',
      this.outsideClickListener.bind(this)
    )
  },

  methods: {
    outsideClickListener() {
      this.opened = false
    },

    toggle() {
      this.opened = !this.opened
    }
  }
})
