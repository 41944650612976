import { Model } from '@vuex-orm/core'
import Category from './Category'
import { Request } from '@vuex-orm/plugin-axios'

export enum InterestCalculType {
  //INFINE = 'inFine',
  LINEAR = 'linear'
  //DEGRESSIF = 'degressif'
}

export const InterestCalculTypeReadable = [
  //{ value: InterestCalculType.INFINE, label: 'InFine' },
  { value: InterestCalculType.LINEAR, label: 'Amortissement linéaire' }
  //{ value: InterestCalculType.DEGRESSIF, label: 'Amortissement dégressif' }
]

export interface ReimbursementDetails {
  allowDeferredPaymentForBeneficiary: boolean
  beneficiaryDeferredPaymentMinDuration?: number
  beneficiaryDeferredPaymentMaxDuration?: number
  allowDeferredPaymentForSfd: boolean
  sfdDeferredPaymentMinDuration?: number
  sfdDeferredPaymentMaxDuration?: number
  allowGraceDelayForSfd: boolean
  sfdGraceDelayMinDuration?: number
  sfdGraceDelayMaxDuration?: number
  beneficiaryPaymentPeriodicity: number
  sfdPaymentPeriodicity: number
  beneficiaryInterestRate: number
  sfdInterestRate: number
}
export interface CreditDetails {
  beneficiaryMinAmount: number
  beneficiaryMaxAmount: number
  /* sfdMinAmount: number
  sfdMaxAmount: number */
  beneficiaryLoanMinDuration: number
  beneficiaryLoanMaxDuration: number
  sfdLoanMinDuration: number
  sfdLoanMaxDuration: number
  beneficiaryInterestCalculMode: InterestCalculType
  sfdInterestCalculMode: InterestCalculType
  nbOfBeneficiaryPerGroup: number[]
  allowWithdrawFees: boolean
  moovFees?: number
  mtnFees?: number
}
export default class Product extends Model {
  static entity = 'products'
  id!: string
  name!: string
  productNumber!: string
  description!: string
  financementType!: string[]
  manageFinancialFlow!: boolean
  url!: string
  category!: Category
  sfdConcern!: []
  creditDetails!: CreditDetails
  reimbursementDetails!: ReimbursementDetails
  createdAt!: string

  static fields() {
    return {
      id: this.attr(null),
      name: this.string(''),
      productNumber: this.string(''),
      description: this.string(''),
      financementType: this.attr(null),
      manageFinancialFlow: this.attr(''),
      url: this.attr(null),
      sfdConcern: this.attr(null),
      categoryId: this.attr(null),
      category: this.belongsTo(Category, 'categoryId'),
      creditDetails: this.attr(null),
      reimbursementDetails: this.attr(null),
      createdAt: this.attr(null)
    }
  }

  static apiConfig = {
    actions: {
      find(this: any, id: string) {
        console.log('Product find call', id)

        return this.get(`/products/${id}`)
      },
      fetchAll(this: Request) {
        return this.get('/products')
      },
      create(this: Request, data: any) {
        return this.post('/products', data)
      },
      remove(this: Request, id: string) {
        return this.delete(`/products/${id}`, {
          delete: 1
        })
      },
      updateOne(this: Request, id: string, data: any) {
        return this.patch(`/products/update/${id}`, data)
      }
    }
  }
}
