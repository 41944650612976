import { Model } from '@vuex-orm/core'

export default class OrganizationBalance extends Model {
  static entity = 'organization_balances'

  id!: string
  type!: string
  otm!: string
  balance!: number
  updateAt!: string
  organizationId!: string

  static fields() {
    return {
      id: this.uid(),
      organizationId: this.string(''),
      type: this.string(''),
      otm: this.string(''),
      balance: this.number(0),
      updatedAt: this.string('')
    }
  }

  static async incrementBalance(options: {
    organizationId: string
    otm: string
    type: string
    amount: number
  }) {
    const balance = this.query()
      .where('organizationId', options.organizationId)
      .where('otm', options.otm.toLowerCase())
      .where('type', options.type)
      .first()

    if (balance) {
      return this.dispatch('update', {
        where: balance.id,
        data: {
          balance: balance.balance + options.amount
        }
      })
    }
  }

  static async decrementBalance(options: {
    organizationId: string
    otm: string
    type: string
    amount: number
  }) {
    const balance = this.query()
      .where('organizationId', options.organizationId)
      .where('otm', options.otm.toLowerCase())
      .where('type', options.type)
      .first()

    if (balance) {
      return this.dispatch('update', {
        where: balance.id,
        data: {
          balance: balance.balance - options.amount
        }
      })
    }
  }
}
