






































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import TheHeader from '@/components/TheHeader.vue'
import TheSidebar from '@/components/TheSidebar.vue'
import { RouteRecord } from 'vue-router'

export default Vue.extend({
  components: {
    TheHeader,
    TheSidebar
  },

  data() {
    return {
      DashMenu: [
        {
          text: 'Dashboard',
          icon: '',
          iconSize: '',
          route: '/dashboard'
        }
      ]
    }
  },

  props: {
    menu: {
      type: Array,
      default: () => []
    },

    notifications: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('product', ['getCurrentProduct']),

    pageTitle(): string {
      const routeWithTitle = this.$route.matched.find(
        (r: RouteRecord) => r.meta.title
      )
      return routeWithTitle ? routeWithTitle.meta.title : ''
    },

    isPricipalDashboard() {
      return this.$route.meta?.dashboard
    },

    menuData() {
      return this.menu
    }
  },

  methods: {
    ...mapActions('auth', ['logout']),

    onLogout() {
      ;(this as any).logout().then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
})
