import { RouteConfig } from 'vue-router'

export const AuthRoutes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/login.vue'),
    meta: {
      auth: 'guest'
    }
  },
  {
    path: '/2fa',
    name: '2fa',
    component: () => import('./pages/2fa.vue')
  },
  {
    path: '/passwords/forgotten',
    name: 'passwords.forgotten',
    component: () => import('./pages/passwords/forgotten.vue'),
    meta: {
      auth: 'guest'
    }
  },
  {
    path: '/passwords/reset',
    name: 'passwords.reset',
    component: () => import('./pages/passwords/reset.vue'),
    meta: {
      auth: 'guest'
    }
  }
]
