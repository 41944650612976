import { Module } from 'vuex'
import { ICreditLineProvisionState, CreditLineProvisionState } from './state'
import { IRootState } from '../root'
import { CreditLineProvisionGetters } from './getters'
import { CreditLineProvisionActions } from './actions'
import { CreditLineProvisionMutations } from './mutations'

export const CreditLineProvisionModule: Module<
  ICreditLineProvisionState,
  IRootState
> = {
  namespaced: true,

  state: {
    ...CreditLineProvisionState()
  },

  getters: {
    ...CreditLineProvisionGetters
  },

  actions: {
    ...CreditLineProvisionActions
  },

  mutations: {
    ...CreditLineProvisionMutations
  }
}
