import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import { IRootState } from './modules/root'
import RootModule from './modules/root'
import AuthModule from './modules/auth'
import FicheClient from './modules/fiche-client'
import Categories from './modules/categories'

import database from './database'
import api from '@/api'
import { ProductModule } from './modules/product'

Vue.use(Vuex)
VuexORM.use(VuexORMAxios, { axios: api })

const store: Store<IRootState> = new Store({
  ...RootModule,

  modules: {
    auth: AuthModule,
    ficheClient: FicheClient,
    categories: Categories,
    product: ProductModule
  },

  plugins: [VuexORM.install(database)]
})

export default store
