import { ActionContext } from 'vuex'
import { IRootState } from './state'
import axios from 'axios'
import api, { ApiResponse } from '@/api'
import { Types } from './mutations'
import { AUTH_ACCESS_TOKEN_KEY } from '@/constants'

export interface IRootActions {
  fetchBeneficiaryCounts(
    context: ActionContext<IRootState, IRootState>
  ): Promise<ApiResponse>

  fetchAccountBalancesV1(context: ActionContext<IRootState, IRootState>): any

  checkSfdAccessToProdij(
    context: ActionContext<IRootState, IRootState>
  ): Promise<boolean>
}

export const RootActions: IRootActions = {
  fetchBeneficiaryCounts({ commit }) {
    return api.countBeneficiaries().then((response: ApiResponse) => {
      if (response.isSuccessful) {
        commit(Types.ADD_BENEFICIARY_COUNTS, response.data)
      }

      return response
    })
  },

  fetchAccountBalancesV1({ commit }) {
    const accessToken = sessionStorage.getItem(AUTH_ACCESS_TOKEN_KEY)
    const url =
      process.env.VUE_APP_V1_API_BASE_URL +
      '/kkiapay-accounts/get-account-balances/'
    return axios.request({
      url,
      headers: {
        Authorization: 'Bearer ' + accessToken
      },
      params: {
        referer: 'v2'
      }
    })
  },

  async checkSfdAccessToProdij(): Promise<boolean> {
    console.log('process.env.:', process.env)
    const accessToken = sessionStorage.getItem(AUTH_ACCESS_TOKEN_KEY)
    const url = process.env.VUE_APP_PRODIJ_API_URL + '/sfd/check-access/sfd'

    const result = await axios.request({
      url,
      headers: {
        Authorization: 'Bearer ' + accessToken
      },
      params: {
        referer: 'v2'
      }
    })

    return result.data
  }
}
