import { Model } from '@vuex-orm/core'
import { Request } from '@vuex-orm/plugin-axios'
import Reimbursement from './Reimbursement'

export default class BeneficiaryGroup extends Model {
  static entity = 'beneficiary_groups'

  id!: string
  name!: string
  totalReceived!: number
  totalDue!: number
  totalPaid!: number
  dispatchingId!: string
  members!: string[]

  get progress(): number {
    return (this.totalDue / this.totalPaid) * 100
  }

  static fields() {
    return {
      id: this.string(null),
      name: this.string(''),
      totalReceived: this.number(0),
      totalDue: this.number(0),
      totalPaid: this.number(0),
      createdAt: this.string(''),
      members: this.attr([]),
      dispatchingId: this.string(''),

      reimbursements: this.hasMany(Reimbursement, 'beneficiaryGroupId')
    }
  }

  static forCurrentOrganization() {
    const currentOrganizationId = this.store().getters['auth/user']
      .organizationId

    return this.query()
      .where('organizationId', currentOrganizationId)
      .withAll()
      .get()
  }

  static byDispatching(dispatchingId: string) {
    return this.query()
      .where('dispatchingId', dispatchingId)
      .get()
  }

  static apiConfig = {
    actions: {
      fetch(
        this: Request,
        options: { include?: []; dispatchingId?: string } = {}
      ) {
        const { include, ...params } = options

        if (include) {
          ;(params as any).populate = include.join(',')
        }

        return this.get('/beneficiary-groups', { params })
      },

      fetchByDispatching(this: Request, dispatchingId: string) {
        return this.fetch({ dispatchingId })
      },

      fetchByCreditLine(this: Request, creditLineId: string) {
        return this.get(`/credit-lines/${creditLineId}/beneficiary-groups`)
      }
    }
  }
}
