

























import Vue from 'vue'

export default Vue.extend({
  props: {
    pageTitle: {
      type: String,
      default: ''
    },

    user: {
      type: Object,
      required: true
    }
  },

  computed: {
    avatarString(): string {
      return `${this.user.lastname[0]}${this.user.firstname[0]}`
    }
  }
})
