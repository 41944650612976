

















import Vue from 'vue'

const typeColors: Record<string, string> = {
  info: 'blue',
  success: 'green',
  error: 'red'
}

const typeIcons: Record<string, string> = {
  info: 'info',
  success: 'check-circle',
  error: 'error'
}

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: 'info',
      validator: (type: string) => /info|success|error/.test(type)
    },

    show: {
      type: [Boolean, Number, String],
      default: true
    },

    dismissible: Boolean,

    message: String
  },

  data() {
    return {
      visible: !!this.show,
      dismissDelay: 0
    }
  },

  computed: {
    color(): string {
      return typeColors[this.type] || typeColors.info
    },

    icon(): string {
      return typeIcons[this.type] || typeIcons.info
    },

    classes(): string[] {
      return [
        'p-3 rounded border flex items-start',
        `text-${this.color}-300`,
        `bg-${this.color}-100`,
        `border-${this.color}-200`
      ]
    }
  },

  watch: {
    show: {
      immediate: true,
      handler(show) {
        if (this.visible !== show) {
          this.visible = !!show
          if (['number', 'string'].includes(typeof this.show)) {
            this.dismissDelay = Number(this.show)
          }

          if (this.dismissDelay) {
            setTimeout(() => {
              this.visible = false
            }, this.dismissDelay)
          }
        }
      }
    }
  }
})
