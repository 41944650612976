import { ActionContext } from 'vuex'
import { Deadline, ICreditLineProvisionState, NewProvision } from './state'
import { Types } from './mutations'
import { IRootState } from '../root'

export interface ICreditLineProvisionActions {
  updateNewProvisionInfos(
    context: ActionContext<ICreditLineProvisionState, IRootState>,
    infos: NewProvision
  ): void

  updateNewProvisionAmount(
    context: ActionContext<ICreditLineProvisionState, IRootState>,
    data: { otm: string; amount: number }
  ): void

  generateNewProvisionDeadlines(
    context: ActionContext<ICreditLineProvisionState, IRootState>,
    otm: string
  ): Deadline[]

  editNewProvisionDeadline(
    context: ActionContext<ICreditLineProvisionState, IRootState>,
    data: { index: number; data: Deadline }
  ): void

  deleteNewProvisionDeadline(
    context: ActionContext<ICreditLineProvisionState, IRootState>,
    data: { index: number; otm: string }
  ): void
}

export const CreditLineProvisionActions: ICreditLineProvisionActions = {
  updateNewProvisionInfos({ commit, state, rootGetters }, infos) {
    const product = rootGetters['product/getCurrentProduct']
    commit(Types.UPDATE_NEW_PROVISION_INFO, infos)
    if (
      state.newProvision.durationInMonth !==
      state.newProvision.mtnDeadlines.length
    ) {
      state.newProvision.mtnAmount > 0 &&
        commit(Types.GENERATE_NEW_PROVISION_DEADLINES, { otm: 'mtn', product })
      state.newProvision.moovAmount > 0 &&
        commit(Types.GENERATE_NEW_PROVISION_DEADLINES, { otm: 'moov', product })
    }
  },

  updateNewProvisionAmount({ commit }, data) {
    commit(Types.UPDATE_NEW_PROVISION_AMOUNT, data)
  },

  generateNewProvisionDeadlines({ commit, state, rootGetters }, otm) {
    const product = rootGetters['product/getCurrentProduct']
    commit(Types.GENERATE_NEW_PROVISION_DEADLINES, { otm, product })
    return otm === 'mtn'
      ? state.newProvision.mtnDeadlines
      : state.newProvision.moovDeadlines
  },

  editNewProvisionDeadline({ commit }, values) {
    commit(Types.EDIT_NEW_PROVISION_DEADLINE, values)
  },

  deleteNewProvisionDeadline({ commit }, data) {
    commit(Types.DELETE_NEW_PROVISION_DEADLINE, data)
  }
}
