import { IAuthState } from './state'
import User from '@/store/models/User'

export interface IAuthGetters {
  authenticated(state: IAuthState): boolean
  twoFA(state: IAuthState): boolean
  userId(state: IAuthState): string
  user(state: IAuthState, getters: any, rootState: any, rootGetters: any): User
  userRole(state: IAuthState, getters: any): string
}

export const AuthGetters: IAuthGetters = {
  authenticated(state: IAuthState): boolean {
    return !!state.accessToken
  },

  twoFA(state: IAuthState): boolean {
    return state.twoFA
  },

  userId(state: IAuthState): string {
    return state.userId as string
  },

  user(state: IAuthState, _, __, rootGetters): User {
    return rootGetters['entities/users/find'](state.userId)
  },

  userRole(state: IAuthState, getters: any): string {
    return getters.user ? (getters.user as User).role.split(':')[1] : 'guest'
  }
}
