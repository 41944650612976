import { BeneficiaryCounts, IRootState } from './state'

interface BeneficiaryCountsBySfd {
  (id: string): number
}

export interface IRootGetters {
  beneficiaryCounts(state: IRootState): BeneficiaryCounts[]
  totalBeneficiaryCounts(state: IRootState): number
  beneficiaryCountsBySfd(state: IRootState): BeneficiaryCountsBySfd
}

export const RootGetters: IRootGetters = {
  beneficiaryCounts(state) {
    return state.beneficiaryCountsBySfdIds.map(
      (id: string) => state.beneficiaryCountsBySfd[id]
    )
  },

  totalBeneficiaryCounts(state) {
    return state.beneficiaryCountsBySfdIds
      .map((id: string) => state.beneficiaryCountsBySfd[id])
      .reduce((total, item: BeneficiaryCounts) => total + item.beneficiaries, 0)
  },

  beneficiaryCountsBySfd(state) {
    return (id: string) => state.beneficiaryCountsBySfd[id].beneficiaries
  }
}
