






import Vue from 'vue'
export default Vue.extend({
  props: {
    color: {
      type: String,
      default: 'blue'
    },

    text: String
  }
})
