import { Model } from '@vuex-orm/core'
import store from '..'
import Product from './Product'
import Reimbursement from './Reimbursement'

export default class ReimbursementDeadline extends Model {
  static entity = 'reimbursement_deadlines'

  id!: string
  type!: string
  state!: string
  date!: string
  paidAmount!: number
  dueAmount!: number
  beneficiaryGroupId!: string
  productId!: string

  static fields() {
    return {
      id: this.string(null),
      type: this.string(''),
      state: this.string('pending'),
      date: this.string(''),
      paidAmount: this.number(0),
      dueAmount: this.number(0),
      createdAt: this.string(''),
      productId: this.string(''),
      product: this.belongsTo(Product, 'productId'),

      beneficiaryGroupId: this.string(''),

      reimbursements: this.hasMany(Reimbursement, 'reimbursementDeadlineId')
    }
  }

  static byBeneficiaryGroup(groupId: string) {
    return this.query()
      .where('beneficiaryGroupId', groupId)
      .withAllRecursive()
      .get()
  }

  static apiConfig = {
    actions: {
      fetchByBeneficiaryGroup(this: any, groupId: string) {
        const product = store.getters['product/getCurrentProduct']
        return this.get('/reimbursement-deadlines', {
          params: {
            beneficiaryGroupId: groupId
          }
        })
      }
    }
  }
}
