





























import Vue from 'vue'

const MARGINS: Record<string, number[]> = {
  xsmall: [4, 0],
  small: [4, 2],
  medium: [6, 3],
  large: [8, 4]
}

const TEXT_SIZES: Record<string, string> = {
  small: 'xs',
  medium: 'base',
  large: 'lg'
}

export default Vue.extend({
  inheritAttrs: false,

  props: {
    to: {
      type: [String, Object],
      default: null
    },

    href: {
      type: String,
      default: null
    },

    text: {
      type: String,
      required: false
    },

    color: {
      type: String,
      default: 'primary'
    },

    textColor: {
      type: String,
      default: 'white'
    },

    block: {
      type: Boolean,
      default: false
    },

    xsmall: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    },

    large: {
      type: Boolean,
      default: false
    },

    outline: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      required: false
    }
  },

  computed: {
    tag() {
      return this.href ? 'a' : this.to ? 'router-link' : 'button'
    },

    size() {
      return this.small
        ? 'small'
        : this.xsmall
        ? 'xsmall'
        : this.large
        ? 'large'
        : 'medium'
    },

    spinnerSize(this: any) {
      return MARGINS[this.size][0]
    },

    classes(this: any) {
      const classes = [
        'relative inline-block cursor-pointer uppercase rounded hover:shadow-lg outline-none focus:outline-none',
        `bg-${this.outline ? 'transparent' : this.color}`,
        `text-${this.outline ? this.color : this.textColor}`,
        `px-${MARGINS[this.size][this.icon ? 1 : 0]} py-${
          MARGINS[this.size][1]
        }`,
        `text-${TEXT_SIZES[this.size]}`,
        {
          'rounded-full': this.icon,
          'w-full': this.block,
          loading: this.loading,
          disabled: this.disabled
        }
      ]

      if (this.outline) {
        classes.push(
          `border border-${this.color} hover:bg-${this.color} hover:text-${this.textColor}`
        )
      }

      return classes
    }
  }
})
