import { Model } from '@vuex-orm/core'
import User from './User'
import CreditLine from './CreditLine'
import Organization from './Organization'
import Transaction from './Transaction'
import { Request } from '@vuex-orm/plugin-axios'
import store from '..'
import Product from './Product'

export default class CreditLineProvision extends Model {
  static entity = 'credit_line_provisions'

  id!: string
  otm!: string
  amount!: number
  reference!: string
  durationInMonth!: number
  annualRate!: number
  deferred!: number
  creator!: User
  creditLine!: CreditLine
  organization!: Organization
  transaction!: Transaction
  productId!: string

  static fields() {
    return {
      id: this.string(''),
      otm: this.string(''),
      state: this.string('pending'),
      amount: this.number(0),
      reference: this.string(''),
      durationInMonth: this.number(0),
      annualRate: this.number(0),
      deferred: this.number(0),
      createdAt: this.string(''),

      createdById: this.string(''),
      createdBy: this.belongsTo(User, 'createdById'),

      creditLineId: this.string(''),
      creditLine: this.belongsTo(CreditLine, 'creditLineId'),

      organizationId: this.string(''),
      organization: this.belongsTo(Organization, 'organizationId'),

      transactionId: this.string(''),
      transaction: this.belongsTo(Transaction, 'transactionId'),

      productId: this.string(''),
      product: this.belongsTo(Product, 'productId')
    }
  }

  static forCurrentOrganization() {
    const currentOrganizationId = this.store().getters['auth/user']
      .organizationId
    const product = store.getters['product/getCurrentProduct']
    return this.query()
      .where('organizationId', currentOrganizationId)
      .where('productId', product.id)
      .withAll()
      .get()
  }

  static apiConfig = {
    actions: {
      fetch(
        this: Request,
        options: { include?: string[]; organizationId?: string }
      ): any {
        const product = store.getters['product/getCurrentProduct']
        const params: Record<string, unknown> = {
          sort: '-createdAt',
          productId: product.id
        }

        if (options.include) {
          params.populate = options.include.join(',')
        }

        if (options.organizationId) {
          params.organizationId = options.organizationId
        }

        return this.get(`/credit-line-provisions`, { params })
      },

      fetchForCurrentOrganization(
        this: Request,
        options: { include: string[] }
      ) {
        const currentOrganizationId = this.model.store().getters['auth/user']
          .organizationId

        return this.fetch({
          ...(options || {}),
          organizationId: currentOrganizationId
        })
      },

      create(this: any, data: any): any {
        return this.post(`/organizations/${data.sfd}/credit`, data, {
          save: false
        })
      },

      /**
       * TODO: refactor this
       */
      validateProvision(
        this: any,
        data: { sfd: string; ids: string[]; otp: string }
      ) {
        return this.post(`/organizations/${data.sfd}/validate-credit`, {
          ids: data.ids,
          otp: data.otp
        })
      }
    }
  }
}
