import { Deadline, ICreditLineProvisionState, NewProvision } from './state'

export interface ICreditLineProvisionGetters {
  newProvision(state: ICreditLineProvisionState): NewProvision
  newProvisionDeadlines(
    state: ICreditLineProvisionState
  ): (otm: string) => Deadline[]
}

export const CreditLineProvisionGetters: ICreditLineProvisionGetters = {
  newProvision(state) {
    return state.newProvision
  },

  newProvisionDeadlines(state) {
    return (otm: string) =>
      otm === 'mtn'
        ? state.newProvision.mtnDeadlines
        : state.newProvision.moovDeadlines
  }
}
