import Vue from 'vue'
import LogRocket from 'logrocket'
import App from './App.vue'
import router, { loadFNMRoutes, loadSFDRoutes } from './router'
import store from './store'
import acl from './plugins/acl'

import './plugins/validation'
import './plugins/uikit'
import './plugins/notify'
import './plugins/filters'

Vue.use(acl, {
  router,
  role() {
    return store.getters['auth/userRole']
  }
})
declare module 'vue/types/vue' {
  interface Vue {
    $granted(role: string | string[]): boolean
  }
}

Vue.config.productionTip = false

LogRocket.init('krwedq/fnm-staging')

store.dispatch('auth/check').then(response => {
  if (response && response.isSuccessful) {
    console.log('response======>', response)

    const user = response.data

    if (user.organization) {
      if (user.organization.type === 'fnm') {
        loadFNMRoutes()
      } else {
        loadSFDRoutes()
      }
    }


    LogRocket.identify(user.id, {
      name: `${user.firstname} ${user.lastname}`,
      ...user
    })
  }

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
