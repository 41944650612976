import { Model } from '@vuex-orm/core'

export default class Beneficiary extends Model {
  static entity = 'beneficiaries'

  id!: string
  name!: string
  gender!: string
  phone!: string
  npi!: string

  static fields() {
    return {
      id: this.string(null),
      name: this.string(''),
      gender: this.string(''),
      phone: this.string(''),
      npi: this.string('')
    }
  }
}
