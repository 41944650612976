import state from './state'

const getters = {
  getBeneficiaries: () => {
    return state.allBeneficiaries
  },
  getAll: () => {
    return state.all
  },
  getOneBeneficiaries() {
    console.log(state.oneBeneficiarie, 'gettershh')
    return state.oneBeneficiarie
  },
  getCurrentBeneficiaries: () => {
    return state.currentBeneficiarie
  }
}
export default getters
