import RouterViewHolder from '@/components/RouterViewHolder.vue'
import { RouteWithSidebar } from '@/types/router'
// import { FNM_ROLES } from '@/constants'

export const FNMRoutes: any[] = [
  {
    path: '/dashboard/:productId',
    name: 'dashboard',
    component: () => import('./pages/index.vue'),
    sidebar: {
      text: 'Tableau de bord',
      icon: 'dashboard'
    },
    meta: {
      title: 'Tableau de bord'
    }
  },
  {
    path: '/dashboard/:productId',
    component: RouterViewHolder,
    sidebar: {
      text: 'Opérations'
    },
    children: [
      {
        path: 'sfds',
        name: 'sfds.indexSfdProduct',
        component: () => import('./pages/sfds/indexSfdProduct.vue'),
        sidebar: {
          icon: 'building',
          text: 'SFDs',
          ignoreChildren: true
        },
      },
      {
        path: 'creditlines',
        name: 'creditlines.index',
        component: () => import('./pages/creditlines/index.vue'),
        sidebar: {
          text: 'Approvisionnements',
          icon: 'balance'
        }
      },
      {
        path: 'creditlines/create',
        component: () => import('./pages/creditlines/create/index.vue'),
        meta: {
          role: 'accountmanager|operationmanager'
        },
        sidebar: {},
        children: [
          {
            path: '',
            name: 'creditlines.create',
            component: () => import('./pages/creditlines/create/infos.vue'),
            meta: {
              step: 0,
              stepTitle: 'Informations générales'
            },
            sidebar: {
              icon: 'money-out',
              text: 'Approvisionner un SFD',
              ignoreChildren: true
            }
          },
          {
            path: 'summary',
            name: 'creditlines.create.summary',
            component: () => import('./pages/creditlines/create/summary.vue'),
            meta: {
              step: 3,
              stepTitle: 'Récapitulatif'
            }
          },
          {
            path: ':otm',
            name: 'creditlines.create.deadlines',
            component: () => import('./pages/creditlines/create/deadlines.vue'),
            meta: {
              step: 1,
              stepTitle: ({ params }: { params: any }) =>
                `Montant à verser pour ${params.otm}`
            }
          }
        ]
      },
      {
        path: 'creditlines/:creditLineId/details',
        name: 'creditlines.show',
        component: () => import('./pages/creditlines/show.vue'),
        meta: {
          title: 'Details de la ligne de crédit'
        }
      },
      {
        path: 'feed',
        name: 'disbursments.feed',
        component: () => import('./pages/fund-account.vue'),
        sidebar: {
          icon: 'money-in',
          iconSize: 6,
          text: 'Alimenter le compte de décaissement'
        },
        meta: {
          role: 'financialmanager|ceo'
        }
      },
      {
        path: 'ova',
        name: 'ova',
        component: () => import('../FNM/pages/product/ova.vue'),
        sidebar: {
          text: 'Comptes',
          icon: 'portefeuille2-small'
        },
        meta: {
          //dashboard: true,
          role: 'financialmanager|operationmanager|superadmin|ceo'
        }
      },
      {
        path: 'ova/account-preview/:accountKey',
        name: 'account-preview',
        component: () => import('../FNM/pages/product/account-preview.vue'),
        meta: {
          //dashboard: true,
          role: 'financialmanager|operationmanager|superadmin|ceo'
        },
        props: (route: any) => ({
          accountData: {},
          ...route.params
        }),
        beforeEnter: async (to: any, from: any, next: any) => {
          if (from.name != 'ova') next('/ova')
          if (from.name == 'ova' && !to.params.accountData) next('/ova')
          next()
        }
      },
      {
        path: 'reserve-credit',
        name: 'reverseCredit',
        component: () => import('../FNM/pages/product/reserveCredit.vue'),
        sidebar: {
          text: 'Crédit réserve',
          icon: 'reserve-credit'
        },
        meta: {
          title: 'Crédit du compte de réserve',
          //dashboard: true,
          role: 'financialmanager|ceo'
        }
      },
      {
        path: 'transferts',
        name: 'account.transfert',
        component: () => import('./pages/transactions/Transferts.vue'),
        sidebar: {
          icon: 'money-out',
          iconSize: 6,
          text: 'Transferts'
        },
        meta: {
          //title: 'Transferts',
          role: 'financialmanager|ceo'
        }
      },

      {
        path: 'dashboard/ClientFiche/:id',
        name: 'DetailsFiche',
        component: () => import('../FNM/pages/ficheclient/detailsFiche.vue')
      },

      {
        path: '/ClientFiche/:id/credits/detail/:creditLineId',
        name: 'credit.details',
        component: () => import('../FNM/pages/ficheclient/detailcredit.vue')
      }
    ]
  },

  {
    path: '/dashboard/:productId/outils',
    component: RouterViewHolder,
    sidebar: {
      text: 'outils'
    },
    children: [
      {
        path: 'kyc',
        name: 'check',
        component: () => import('../SFD/pages/check.vue'),
        sidebar: {
          icon: 'kyc',
          text: 'KYC Bénéficiaire'
        }
      },
      {
        path: 'ClientFiche',
        name: 'FicheClient',
        component: () => import('../FNM/pages/ficheclient/searchClient.vue'),
        sidebar: {
          icon: 'fc',
          text: 'Fiche Client'
        }
      },
      {
        path: 'verification',
        name: 'Vérification',
        component: () =>
          import('../FNM/pages/verification/searchTransaction.vue'),
        sidebar: {
          icon: 'verif',
          text: 'Vérification'
        }
      }
    ]
  },
  {
    path: '/dashboard/:productId/finances',
    component: RouterViewHolder,
    sidebar: {
      text: 'Comptes'
    },
    children: [
      {
        path: 'balances',
        name: 'finances.balance',
        component: () => import('./pages/sfds/balances.vue'),
        meta: {
          title: 'Solde des OVAs'
        },
        sidebar: {
          text: 'Solde des OVAs',
          icon: 'account-balance'
        }
      },
      {
        path: 'disbursments',
        name: 'finances.disbursments',
        component: () => import('./pages/finances/disbursments/index.vue'),
        meta: {
          role: 'accountmanager|operationmanager|financialmanager'
        },
        sidebar: {
          text: 'Décaissements',
          ignoreChildren: true,
          icon: 'balance'
        }
      },
      {
        path: 'credit-lines/:creditLineId',
        component: () => import('./pages/finances/disbursments/show/index.vue'),
        meta: {
          role: 'accountmanager|operationmanager|financialmanager'
        },
        children: [
          {
            path: 'credits',
            name: 'finances.creditlines.credits',
            component: () =>
              import('./pages/finances/disbursments/show/credits.vue')
          },
          {
            path: 'refunds',
            name: 'finances.creditlines.refunds',
            component: () =>
              import('./pages/finances/disbursments/show/refunds/index.vue')
          }
        ]
      },
      {
        path: 'refunds',
        name: 'finances.refunds',
        component: () => import('./pages/finances/refunds/index.vue'),
        meta: {
          role: 'accountmanager|financialmanager|operationmanager|auditor'
        },
        sidebar: {
          icon: 'money-change',
          text: 'Remboursements',
          ignoreChildren: true
        },
        children: [
          {
            path: ':refundId/details',
            name: 'refunds.details',
            component: () => import('./pages/finances/refunds/details.vue')
          }
        ]
      }
    ]
  },

  {
    path: '/dashboard/:productId/monitoring',
    component: () => import('./layouts/monitoring.vue'),
    sidebar: {},
    children: [
      {
        path: 'reports',
        name: 'monitoring.reports',
        component: () => import('./pages/reports/index.vue')
      },
      {
        path: 'reports/operations',
        name: 'monitoring.reports.operations',
        component: () => import('./pages/reports/periodic.vue'),
        sidebar: {
          icon: 'logs',
          text: 'Rapports',
          separator: true
        }
      },
      {
        path: 'logs',
        name: 'monitoring.logs',
        component: () => import('./pages/logs/index.vue'),
        meta: {
          role: 'superadmin|admin|ceo|auditor|support'
        },
        sidebar: {
          text: 'Journaux',
          icon: 'logs'
        }
      }
    ]
  },

  {
    path: '/dashboard/:productId/settings',
    name: 'settings',
    component: () => import('./pages/settings/index.vue'),
    meta: {
      role: 'financialmanager'
    },
    sidebar: {
      text: 'Paramètres',
      icon: 'user-setting'
    }
  }
]

export const DashboardRoutes: any[] = [
  {
    path: '/',
    name: 'firstDashboard',
    component: () => import('../FNM/pages/product/index.vue'),
    sidebar: {
      text: 'Tableau de bord',
      icon: 'dashboard'
    },
    meta: {
      title: 'Tableau de bord',
      auth: true,
      dashboard: true
    }
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import('./pages/users/index.vue'),
    meta: {
      role: 'admin|superadmin|support',
      dashboard: true
    },
    sidebar: {
      icon: 'users',
      text: 'Utilisateurs',
      ignoreChildren: true
    },
    children: [
      {
        path: 'create',
        name: 'users.create',
        component: () => import('./pages/users/create.vue'),
        meta: {
          role: 'admin|superadmin|support',
          dashboard: true
        }
      },
      {
        path: ':userId/edit',
        name: 'users.edit',
        component: () => import('./pages/users/edit.vue'),
        meta: {
          role: 'admin|superadmin|support',
          dashboard: true
        }
      }
    ]
  },

  {
    path: '/',
    component: RouterViewHolder,
    sidebar: {
      text: 'Produits'
    },
    children: [
      {
        path: 'Categorie',
        name: 'createCategorie',
        component: () => import('../FNM/pages/categories/createCategorie.vue'),
        sidebar: {
          text: 'Catégories',
          icon: 'cat'
        },
        meta: {
          auth: true,
          dashboard: true
        }
      },

      {
        path: 'produit',
        name: 'product',
        component: () => import('../FNM/pages/product/listeProduct.vue'),
        sidebar: {
          text: 'Produits',
          icon: 'produit'
        },
        meta: {
          dashboard: true
        }
      },
      {
        path: 'createProduct',
        name: 'createProduct',
        component: () => import('../FNM/pages/product/createProduct.vue'),
        meta: {
          dashboard: true
        }
      },

      {
        path: 'modifProduct/:productId',
        name: 'modifProduct',
        component: () => import('../FNM/pages/product/modifProduct.vue'),
        meta: {
          dashboard: true
        }
      },
    ]
  },

  {
    path: '/',
    component: RouterViewHolder,
    sidebar: {
      text: 'Opérations'
    },
    children: [
      {
        path: 'sfds',
        name: 'sfds.index',
        component: () => import('./pages/sfds/index.vue'),
        sidebar: {
          icon: 'building',
          text: 'Tous les SFD',
          ignoreChildren: true
        },
        meta: {
          dashboard: true
        },
        children: [
          {
            path: 'sfds/:sfdId/delete',
            name: 'sfds.destroy',
            component: () => import('./pages/sfds/destroy.vue'),
            meta: {
              role: 'superadmin',
              dashboard: true
            }
          }
        ]
      },
      {
        path: 'sfds/create',
        name: 'sfds.create',
        component: () => import('./pages/sfds/create.vue'),
        meta: {
          role: 'superadmin|admin|support',
          dashboard: true
        },
        sidebar: {
          icon: 'building-add',
          text: 'Ajouter un SFD',
          iconSize: 5
        }
      },
      {
        path: 'sfds/:sfdId/edit',
        name: 'sfds.edit',
        component: () => import('./pages/sfds/edit.vue'),
        meta: {
          role: 'superadmin|admin|support',
          dashboard: true
        }
      },
    ]
  },
  
  {
    path: '/',
    component: RouterViewHolder,
    meta: {
      role: 'superadmin|admin|ceo|auditor|support'
    },
    sidebar: {
      text: 'Journaux'
    },
      children:[
        {
          path: 'logs',
          name: 'general-monitoring.logs',
          component: () => import('./pages/logs/general.vue'),
          meta: {
            role: 'superadmin|admin|ceo|auditor|support',
            dashboard: true
          },
          sidebar: {
            text: 'Journaux',
            icon: 'logs'
          }
        }
      ]
  }
  
  
]
