export const AUTH_ACCESS_TOKEN_KEY = 'fnm.auth.access_token'
export const USER_ROLES: Record<string, string> = {
  'fnm:superadmin': 'Super Administrateur',
  'fnm:admin': 'Administrateur',
  'fnm:support': 'Support technique',
  'fnm:ceo': 'Directeur Général',
  'fnm:accountmanager': 'Gestionnaire de portefeuilles',
  'fnm:operationmanager': 'Directeur des opérations',
  'fnm:financialmanager': 'Directeur administratif et financier',
  'fnm:auditor': 'Auditeur',

  'sfd:admin': 'Administrateur',
  'sfd:ceo': 'Directeur exécutif',
  'sfd:agencyhead': "Chef d'agence",
  'sfd:accounting': 'Comptable',
  'sfd:auditor': 'Auditeur'
}

export const FNM_ROLES = Object.keys(USER_ROLES)
  .filter(key => key.startsWith('fnm:'))
  .map((key: string) => ({
    label: USER_ROLES[key],
    field: key
  }))

export const SFD_ROLES = Object.keys(USER_ROLES)
  .filter(key => key.startsWith('sfd:'))
  .map((key: string) => ({
    label: USER_ROLES[key],
    field: key
  }))

export const ALL_ROLES = Object.keys(USER_ROLES).map((key: string) => ({
  label: USER_ROLES[key],
  field: key
}))
