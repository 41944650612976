



















































































import Vue from 'vue'
import VButton from './VButton.vue'

export default Vue.extend({
  components: {
    VButton
  },

  props: {
    active: Boolean,
    persistent: Boolean,
    hideCloseIcon: Boolean,
    noHeader: Boolean,
    noFooter: Boolean,
    fullwidth: Boolean,

    title: {
      type: String,
      default: null
    },

    contentClass: {
      type: [String, Array, Object],
      default: null
    },

    okActionText: {
      type: String,
      default: 'Ok'
    },

    cancelActionText: {
      type: String,
      default: 'Annuler'
    },

    okActionLoading: Boolean
  },

  data() {
    return {
      model: this.active
    }
  },

  watch: {
    active(active) {
      if (active !== this.model) {
        this.model = active
        if (!active) {
          this.$emit('close')
        }
      }
    }
  },

  methods: {
    toggle() {
      this.model = !this.model
      this.$emit('update:active', this.model)
      this.$emit(this.model ? 'open' : 'close')
    },

    onBackdropClick() {
      if (!this.persistent) {
        this.toggle()
      }
    },

    onCloseClick() {
      this.toggle()
    },

    onOkClick() {
      this.$emit('action:ok')
    },

    onCancelClick() {
      this.$emit('action:cancel')
      this.toggle()
    }
  }
})
