import Product from '@/store/models/Product'

import { ProductStateInterface } from './state'

export interface ProductGettersInterface {
  getCurrentProduct(state: ProductStateInterface): Product
}

export const ProductGetters: ProductGettersInterface = {
  getCurrentProduct(state) {
    return state.currentProduct
  }
}
