import { Database } from '@vuex-orm/core'
import User from './models/User'
import Organization from './models/Organization'
import CreditLine from './models/CreditLine'
import CreditLineProvision from './models/CreditLineProvision'
import Category from './models/Category'
import Product from './models/Product'

import Dispatching from './models/Dispatching'
import DispatchingFile from './models/DispatchingFile'

import { CreditLineProvisionModule } from './modules/credit-line-provision'
import { DispatchingFileModule } from './modules/dispatching-file'
import { DispatchingCredit } from './models/DispatchingCredit'
import Beneficiary from './models/Beneficiary'
import Phase from './models/Phase'
import OrganizationBalance from './models/OrganizationBalance'

import Logs from './models/Logs'
import CreditLineDeadline from './models/CreditLineDeadline'
import BeneficiaryGroup from './models/BeneficiaryGroup'
import Transaction from './models/Transaction'
import Reimbursement from './models/Reimbursement'
import ReimbursementDeadline from './models/ReimbursementDeadline'
import Account from './models/Account'
import KkiapayAccount from './models/KkiapayAccount'
import ReimbursementFile from './models/ReimbursementFile'

const database = new Database()

// Register models
database.register(User)
database.register(Phase)
database.register(Organization)
database.register(OrganizationBalance)
database.register(CreditLine)
database.register(CreditLineDeadline)
database.register(CreditLineProvision, CreditLineProvisionModule)
database.register(Dispatching)
database.register(DispatchingCredit)
database.register(DispatchingFile, DispatchingFileModule)
database.register(Beneficiary)
database.register(BeneficiaryGroup)
database.register(Logs)
database.register(Transaction)
database.register(Reimbursement)
database.register(ReimbursementFile)
database.register(ReimbursementDeadline)
database.register(Category)
database.register(Product)
database.register(Account)
database.register(KkiapayAccount)

export default database
