import { Model } from '@vuex-orm/core'
import DispatchingFile from './DispatchingFile'
import CreditLine from './CreditLine'
import moment from 'moment'
import BeneficiaryGroup from './BeneficiaryGroup'
import { Request } from '@vuex-orm/plugin-axios'
import Product from './Product'
import store from '..'

export enum DispatchingState {
  ALL = '',
  CREATED = 'created',
  PENDIND = 'pending',
  COMPLETED = 'completed',
  CANCELED = 'canceled'
}

export default class Dispatching extends Model {
  static entity = 'dispatchings'

  id!: string
  state!: string
  createdAt!: string
  creditLineId!: string
  dispatchingFileId!: string
  createdById!: string
  organizationId!: string
  reference!: string
  nbBeneficiaries!: number
  validatedAt!: string
  relaunchCreditAt!: string

  beneficiaryGroups!: BeneficiaryGroup[]

  static fields() {
    return {
      id: this.string(''),
      state: this.string(''),
      createdAt: this.string(''),
      relaunchCreditAt: this.string(''),
      validatedAt: this.string(''),
      creditLineId: this.string(null),
      creditLine: this.belongsTo(CreditLine, 'creditLineId'),

      dispatchingFileId: this.string(null),
      dispatchingFile: this.belongsTo(DispatchingFile, 'dispatchingFileId'),

      createdById: this.string(null),
      organizationId: this.string(null),

      product: this.belongsTo(Product, 'productId'),
      productId: this.string(''),

      reference: this.string(''),
      nbBeneficiaries: this.number(0),

      beneficiaryGroups: this.hasMany(BeneficiaryGroup, 'dispatchingId')
    }
  }

  get timestamp(): number {
    return moment(this.createdAt).unix()
  }

  get totalReceived(): number {
    return this.beneficiaryGroups.reduce(
      (total, beneficiaryGroup) => total + beneficiaryGroup.totalReceived,
      0
    )
  }

  get totalDue(): number {
    return this.beneficiaryGroups.reduce(
      (total, beneficiaryGroup) => total + beneficiaryGroup.totalDue,
      0
    )
  }

  get totalPaid(): number {
    return this.beneficiaryGroups.reduce(
      (total, beneficiaryGroup) => total + beneficiaryGroup.totalPaid,
      0
    )
  }

  get reimbursementProgress(): number | string {
    return this.totalDue > 0
      ? ((this.totalPaid / this.totalDue) * 100).toFixed(2)
      : 0
  }

  static apiConfig = {
    actions: {
      fetch(
        this: Request,
        options: {
          include?: string[]
          organizationId?: string
          state?: string
          productId?: string
        } = {}
      ) {
        const product = store.getters['product/getCurrentProduct']
        const { include, ...params } = options

        params.productId = product.id

        if (include) {
          ;(params as any).populate = include.join(',')
        }

        console.log('params', params)

        return this.get(`/dispatchings`, { params })
      },

      getOne(this: any, id: string) {
        return this.get(`/dispatchings/${id}`)
      },

      initiate(this: any, dispatchingFileId: string) {
        return this.post('/dispatchings', { dispatchingFileId })
      },

      validate(this: Request, dispatchingId: string, otp?: string) {
        const data = otp ? { otp } : {}
        return this.put(`/dispatchings/${dispatchingId}/validate`, data)
      },

      cancel(this: any, dispatchingId: string) {
        return this.put(`/dispatchings/${dispatchingId}/cancel`)
      }
    }
  }
}
