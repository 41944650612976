





















































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

const ICON_SIZES: Record<string, number> = {
  small: 4,
  medium: 6,
  large: 8
}

const MARGINS: Record<string, number> = {
  small: 2,
  medium: 3,
  large: 4
}

export default Vue.extend({
  inheritAttrs: false,

  components: {
    ValidationProvider
  },

  props: {
    validation: {
      type: [String, Object],
      default: ''
    },

    value: {
      type: [String, Number, Object],
      default: null
    },
    selectDisabledOptionText: {
      type: String,
      default: ''
    },
    selectOptions: {
      type: Array,
      default: null
    },

    label: String,
    leftIcon: String,
    rightIcon: String,
    small: Boolean,
    large: Boolean
  },

  data() {
    return {
      model: this.value || '',
      hasFocus: false
    }
  },

  computed: {
    listeners(this: any) {
      return {
        ...this.$listeners,
        input: (event: any) => {
          this.$emit('selectInput', event.target.value)
          this.$emit('input', event.target.value)
        },

        focus: (event: any) => {
          this.hasFocus = true
          this.$emit('focus', event)
        },
        blur: (event: any) => {
          this.hasFocus = false
          this.$emit('blur', event)
        }
      }
    },

    size(): string {
      return this.small ? 'small' : this.large ? 'large' : 'medium'
    },

    leftIconWrapperClasses() {
      return `absolute z-10 pl-${MARGINS[this.size as string]}`
    },

    rightIconWrapperClasses() {
      return `absolute z-10 right-0 pr-${MARGINS[this.size as string]}`
    }
  },

  watch: {
    value(this: any, newValue) {
      if (newValue !== this.model) {
        this.model = newValue
      }
    },

    model(value) {
      this.$emit('selectInput', value)
      this.$emit('input', value)
    }
  },

  methods: {
    iconClasses(this: any, validator: any) {
      const classes = [
        `fill-current w-${ICON_SIZES[this.size]} h-${ICON_SIZES[this.size]}`
      ]

      if (validator.failed) {
        if (this.hasFocus) {
          classes.push('text-red-300')
        } else {
          classes.push('text-red-200')
        }
      } else {
        if (this.hasFocus) {
          classes.push('text-primary')
        } else {
          classes.push('text-gray-500')
        }
      }

      return classes
    },

    inputClasses(this: any, validator: any) {
      const classes = [
        'placeholder-gray-500 text-gray-700',
        'relative bg-white bg-white rounded border w-full',
        'outline-none focus:outline-none'
      ]

      if (validator.failed) {
        classes.push('border-red-200 focus:border-red-300 focus:bg-red-100')
      } else {
        classes.push('border-gray-400 focus:border-blue-300 focus:bg-blue-100')
      }

      if (this.small) {
        classes.push('h-8')
      } else if (this.large) {
        classes.push('h-16')
      } else {
        classes.push('h-12')
      }

      return classes
    }
  }
})
