












import Vue from 'vue'
export default Vue.extend({
  props: {
    icon: {
      type: String,
      required: true
    },

    href: String,
    to: [String, Object],

    title: String
  },

  computed: {
    tag(): string {
      return this.href ? 'a' : this.to ? 'router-link' : 'button'
    }
  }
})
