



























import Vue from 'vue'

export default Vue.extend({
  inheritAttrs: false,

  props: {
    label: String,
    value: Boolean
  },

  methods: {
    onChange($event: any) {
      this.$emit('change', $event)
      this.$emit('input', $event.target.checked)
    }
  }
})
