import { Model, Fields } from '@vuex-orm/core'
import User from './User'
import OrganizationBalance from './OrganizationBalance'
import { Request } from '@vuex-orm/plugin-axios'
export default class Organization extends Model {
  static entity = 'organizations'

  id!: string
  type!: string
  name!: string
  email!: string
  contact!: string
  address!: string
  interventionZone!: string
  nbAgency = 0
  representativeName!: string
  balances!: OrganizationBalance[]

  static fields(): Fields {
    return {
      id: this.string(''),
      type: this.string(''),
      name: this.string(''),
      email: this.string(''),
      contact: this.string(''),
      address: this.string(''),
      interventionZone: this.string(''),
      nbAgency: this.number(0),
      representativeName: this.string(''),
      createdAt: this.string(null),
      updatedAt: this.string(null),

      adminId: this.string(''),
      admin: this.belongsTo(User, 'adminId'),

      balances: this.hasMany(OrganizationBalance, 'organizationId')
    }
  }

  get mtnBalance() {
    return this.defaultBalance('mtn')
  }

  get moovBalance() {
    return this.defaultBalance('moov')
  }

  get mtnRefundBalance() {
    return this.refundBalance('mtn')
  }

  get moovRefundBalance() {
    return this.refundBalance('moov')
  }

  get mtnInterestBalance() {
    return this.interestBalance('mtn')
  }

  get moovInterestBalance() {
    return this.interestBalance('moov')
  }

  get totalBalance() {
    return this.mtnBalance + this.moovBalance
  }

  get totalRefundBalance() {
    return this.mtnRefundBalance + this.moovRefundBalance
  }

  get totalInterestBalance() {
    return this.mtnInterestBalance + this.moovInterestBalance
  }

  balanceByTypeAndOtm(type: string, otm: string) {
    const balance: OrganizationBalance | undefined = this.balances.find(
      b => b.type === type && b.otm === otm
    )

    return (balance && balance.balance) || 0
  }

  hasBalance(type: string, otm: string, amount = 0): boolean {
    return this.balanceByTypeAndOtm(type, otm) >= amount
  }

  defaultBalance(otm: string): number {
    return this.balanceByTypeAndOtm('default', otm)
  }

  refundBalance(otm: string): number {
    return this.balanceByTypeAndOtm('refund', otm)
  }

  interestBalance(otm: string): number {
    return this.balanceByTypeAndOtm('interest', otm)
  }

  static loggedIn(this: any) {
    const loggedInOrganizationId = this.store().getters['auth/user']
      .organizationId

    return this.query()
      .withAll()
      .find(loggedInOrganizationId)
  }

  static apiConfig = {
    actions: {
      find(this: any, id: string) {
        return this.get(`/organizations/${id}`)
      },

      fetch(
        this: Request,
        { includes = [] }: { includes: string[] } = { includes: [] }
      ): any {
        const excludes = ['balances', 'kkiapayAccounts']
        return this.get('/organizations', {
          params: {
            type: 'sfd',
            select: excludes
              .filter(p => !includes.includes(p))
              .map(p => '-' + p)
              .join(','),
            populate: 'admin'
          }
        })
      },

      create(this: any, data: object): any {
        return this.post('/organizations', data)
      },

      update(this: any, id: string, data: object): any {
        return this.put(`/organizations/${id}`, data)
      },
      
      blockdispatching(this: any, id: string) {
        return this.post(`/organizations/block-sfd-dispatching/${id}`)
      },

      unblockdispatching(this: any, id: string) {
        return this.post(`/organizations/unblock-sfd-dispatching/${id}`)
      },

      /**
       * Alimenter le compte de décaissement
       * @param this
       * @param data
       */
      fund(this: any, data: object): any {
        const currentOrganizationId = this.model.store().getters['auth/user']
          .organizationId
        return this.post(`/organizations/${currentOrganizationId}/fund`, data, {
          save: false
        })
      },

      validateFund(
        this: any,
        data: {
          otp: string
          transactionId: string
        }
      ) {
        const currentOrganizationId = this.model.store().getters['auth/user']
          .organizationId

        return this.post(
          `/organizations/${currentOrganizationId}/validate-fund`,
          data,
          {
            save: false
          }
        )
      }
    }
  }
}
