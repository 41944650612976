import { Model } from '@vuex-orm/core'
import { Request } from '@vuex-orm/plugin-axios'

export default class Category extends Model {
  static entity = 'categories'

  id!: string
  name!: string
  code!: string
  createdAt!: string

  static fields() {
    return {
      id: this.string(null),
      name: this.string(''),
      code: this.string(''),
      createdAt: this.attr(null)
    }
  }

  static apiConfig = {
    actions: {
      fetchAll(this: Request, search = '') {
        return this.get('/categories', {
          params: {
            search: search
          }
        })
      },
      create(this: Request, data: any) {
        return this.post('/categories', data)
      },
      remove(this: Request, id: string) {
        return this.delete(`/categories/${id}`, {
          delete: 1
        })
      },
      updateOne(this: Request, id: string, data: any) {
        return this.patch(`/categories/update/${id}`, data)
      }
    }
  }
}
