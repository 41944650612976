






import Vue from 'vue'

export default Vue.extend({
  provide() {
    return {
      Menu: this
    }
  },

  props: {
    multiple: Boolean
  },

  data() {
    return {
      items: [],
      itemsCount: 0
    }
  },

  methods: {
    register(this: any, menuItem: any) {
      this.items.push(menuItem)
      const id = this.itemsCount++

      // menuItem.$on('click', () => {
      //   console.log('item clicked')
      // })

      return id
    },

    unregister(this: any, id: number) {
      const index = this.menus.findIndex((m: any) => m.internalId === id)
      if (index !== -1) {
        this.menus.splice(index, 1)
      }
    }
  }
})
