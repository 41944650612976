



















import Vue from 'vue'

export default Vue.extend({
  inheritAttrs: false,

  inject: {
    Menu: {
      default: {
        register() {
          // console.warn('VMenuItem must be used inside VMenu component')
        },
        unregister() {
          // console.warn('VMenuItem must be used inside VMenu component')
        }
      }
    }
  },

  props: {
    icon: String
  },

  data() {
    return {
      internalId: null,
      selected: false
    }
  },

  created(this: any) {
    this.internalId = this.Menu.register(this)
  },

  beforeDestroy(this: any) {
    if (this.Menu.unregister) {
      // this.Menu.unregister(this.internalId)
    }
  },

  methods: {
    toggle() {
      this.selected = !this.selected
      if (this.selected) {
        this.$emit('select')
      }
    }
  }
})
