import Product from '@/store/models/Product'

export interface ProductStateInterface {
  currentProduct: Product | any
}

export const ProductState = (): ProductStateInterface => {
  return {
    currentProduct: {}
  }
}
