import { Model, Fields } from '@vuex-orm/core'
import { USER_ROLES } from '@/constants'
import Organization from './Organization'

export enum UserRole {}

export default class User extends Model {
  static entity = 'users'

  id!: string
  firstname!: string
  lastname!: string
  email!: string
  phone!: string
  role!: string
  active!: boolean
  deletedAt!: string

  static fields(): Fields {
    return {
      id: this.string(''),
      organizationId: this.string(''),
      firstname: this.string(''),
      lastname: this.string(''),
      email: this.string(''),
      phone: this.string('phone'),
      emailVerified: this.boolean(null),
      role: this.string(''),
      active: this.boolean(true),
      createdAt: this.string(null),
      updatedAt: this.string(null),
      deletedAt: this.string(null),

      organization: this.belongsTo(Organization, 'organizationId')
    }
  }

  static apiConfig = {
    actions: {
      fetch(this: any): any {
        return this.get('/users')
      },

      create(this: any, data: object): any {
        return this.post('/users', data)
      },

      update(this: any, id: string, data: object): any {
        return this.put(`/users/${id}`, data)
      },

      remove(this: any, id: string) {
        return this.delete(`/users/${id}`)
      }
    }
  }

  get fullname(): string {
    return `${this.lastname} ${this.firstname}`
  }

  get roleName(): string {
    return USER_ROLES[this.role]
  }
}
