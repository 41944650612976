import { Module } from 'vuex'
import { IDispatchingFileState, DispatchingFileState } from './state'
import { IRootState } from '../root'
import { DispatchingFileGetters } from './getters'
import { DispatchingFileActions } from './actions'
import { DispatchingFileMutations } from './mutations'

export const DispatchingFileModule: Module<
  IDispatchingFileState,
  IRootState
> = {
  namespaced: true,

  state: {
    ...DispatchingFileState()
  },

  getters: {
    ...DispatchingFileGetters
  },

  actions: {
    ...DispatchingFileActions
  },

  mutations: {
    ...DispatchingFileMutations
  }
}
