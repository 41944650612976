

























import Vue from 'vue'

export default Vue.extend({
  inheritAttrs: false,

  props: {
    position: {
      type: String,
      default: 'bottom-left',
      validator: value =>
        /top|right|bottom|left|top-right|top-left|bottom-right|bottom-right/.test(
          value
        )
    }
  },

  data() {
    return {
      opened: false
    }
  },

  computed: {
    classes(): string[] {
      return [`origin-${this.position}`]
    }
  },

  watch: {
    opened(val) {
      this.$emit(val ? 'open' : 'close')
    }
  },

  mounted() {
    document.body.addEventListener(
      'click',
      this.outsideClickListener.bind(this)
    )
  },

  beforeDestroy() {
    document.body.removeEventListener(
      'click',
      this.outsideClickListener.bind(this)
    )
  },

  methods: {
    outsideClickListener() {
      this.opened = false
    },

    toggle() {
      this.opened = !this.opened
    }
  }
})
