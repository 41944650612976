import Vue from 'vue'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import TailwindUI from 'vue-tailwind-ui'
import VCalendar from 'v-calendar'

import VButton from '@/components/VButton.vue'
import VIconButton from '@/components/VIconButton.vue'
import VInput from '@/components/VInput.vue'
import VSelectInput from '@/components/VSelectInput.vue'
import VToggle from '@/components/VToggle.vue'
import VModal from '@/components/VModal.vue'
import VNotification from '@/components/VNotification.vue'
import VDataTable from '@/components/VDataTable.vue'
import VPopover from '@/components/VPopover.vue'
import Pover from '@/components/Pover.vue'
import VMenu from '@/components/VMenu.vue'
import VMenuItem from '@/components/VMenuItem.vue'
import VMenuDivider from '@/components/VMenuDivider.vue'
import VSelect from '@/components/VSelect.vue'
import VSpinner from '@/components/VSpinner.vue'
import VAlert from '@/components/VAlert.vue'
import VLabel from '@/components/VLabel.vue'

import VRadioInput from '@/components/VRadioInput.vue'
import ReportsList from '@/components/ReportsList.vue'

const requireSvgIcon = require.context(
  '@/assets/icons?inline',
  false,
  /[\w-]+\.svg$/
)

requireSvgIcon.keys().forEach(fileName => {
  const componentConfig = requireSvgIcon(fileName)
  const iconName =
    'VIcon' +
    upperFirst(
      camelCase(
        fileName
          .replace(/\.\w+$/, '')
          .split('/')
          .pop()
      )
    )

  Vue.component(iconName, componentConfig.default || componentConfig)
})

Vue.use(TailwindUI)
Vue.use(VCalendar)

Vue.component('VAlert', VAlert)
Vue.component('VLabel', VLabel)
Vue.component('VButton', VButton)
Vue.component('VIconButton', VIconButton)
Vue.component('VInput', VInput)
Vue.component('VSelectInput', VSelectInput)
Vue.component('VToggle', VToggle)
Vue.component('VModal', VModal)
Vue.component('VNotification', VNotification)
Vue.component('VDataTable', VDataTable)
Vue.component('VFnmPopover', VPopover)
Vue.component('Pover', Pover)
Vue.component('VMenu', VMenu)
Vue.component('VMenuItem', VMenuItem)
Vue.component('VMenuDivider', VMenuDivider)
Vue.component('VSelect', VSelect)
Vue.component('VSpinner', VSpinner)
Vue.component('VRadioInput', VRadioInput)
Vue.component('ReportsList', ReportsList)
