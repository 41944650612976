import { Model } from '@vuex-orm/core'
import store from '..'
import CreditLine from './CreditLine'
import { DispatchingState } from './Dispatching'
import Transaction from './Transaction'
import User from './User'

export enum DispatchingCreditState {
  ALL = '',
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
  REFUNDED = 'refunded',
  PENDING = 'pending'
}
export class DispatchingCredit extends Model {
  static entity = 'dispatching_credits'

  id!: string
  state!: string
  agency!: string
  amount!: string
  reference!: string
  beneficiaryId!: string
  dispatchingId!: string

  static fields() {
    return {
      id: this.string(null),
      otm: this.string(''),
      state: this.string(''),
      agency: this.string(''),
      amount: this.number(0),
      reference: this.string(''),
      createdAt: this.string(''),

      kkiapayTransactionId: this.string(''),
      transaction: this.belongsTo(
        Transaction,
        'kkiapayTransactionId',
        'kkiapayTransactionId'
      ),

      createdById: this.string(null),
      initiator: this.belongsTo(User, 'createdById'),

      beneficiaryId: this.string(null),
      beneficiary: this.attr(''),

      creditLineId: this.string(''),
      creditLine: this.belongsTo(CreditLine, 'creditLineId'),

      dispatchingId: this.string(null),
      organizationId: this.string(null)
    }
  }

  static forCurrentOrganization() {
    const currentOrganizationId = this.store().getters['auth/user']
      .organizationId
    const product = store.getters['product/getCurrentProduct']
    return this.query()
      .where('organizationId', currentOrganizationId)
      .where('productId', product.id)
      .withAll()
      .get()
  }

  static apiConfig = {
    actions: {
      fetchForOrganization(this: any, params = {}) {
        const product = store.getters['product/getCurrentProduct']
        return this.get('/dispatching-credits', {
          params: {
            ...params,
            productId: product.id,
            populate: 'creditLine,transaction,createdBy,beneficiary'
          }
        })
      },

      fetchForDispatching(this: any, dispatchingId: string): any {
        return this.get(`/dispatchings/${dispatchingId}/credits`)
      },

      fetchDispatchingCredit(
        this: any,
        dispatchingId: string,
        status: DispatchingState
      ): any {
        return this.get(`/dispatching-credits/dispatching/${dispatchingId}`, {
          params: {
            status: status,
            populate: 'beneficiary'
          },
          persistBy: 'create'
        })
      },

      totalBeneficiaryAmount(
        this: any,
        productId: string,
        organizationId: string
      ) {
        return this.get(
          '/dispatching-credits/all-dispatching-credits-amount/',
          {
            params: {
              productId: productId,
              organizationId: organizationId
            },
            save: false
          }
        )
      },

      relaunch(this: any, dispatchingId: string) {
        return this.post(
          `dispatching-credits/sfd-relaunch-failed-credit/${dispatchingId}`
        )
      }
    }
  }
}
