import { Model } from '@vuex-orm/core'
import User from './User'

export default class Phase extends Model {
  static entity = 'phases'

  id!: string
  name!: string
  minAmount!: number
  maxAmount!: number
  createdById!: number
  createdBy!: User

  static fields() {
    return {
      id: this.string(''),
      name: this.string(''),
      minAmount: this.number(0),
      maxAmount: this.number(0),
      createdById: this.string(''),

      createdBy: this.belongsTo(User, 'createdById')
    }
  }

  static apiConfig = {
    actions: {
      fetch(this: any) {
        return this.get('/phases')
      },

      create(this: any, data: object) {
        return this.post('/phases', data)
      }
    }
  }
}
