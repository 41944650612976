// import { RouteWithSidebar } from '@/types/router'
import RouterViewHolder from '@/components/RouterViewHolder.vue'

//RouteWithSidebar[]
export const ProductRoutes: any[] = [
  {
    path: '/',
    name: 'firstDashboard',
    component: () => import('../SFD/pages/dashboard.vue'),
    sidebar: {
      text: 'Tableau de bord',
      icon: 'dashboard'
    },
    meta: {
      title: 'Tableau de bord',
      auth: true,
      dashboard: true
    }
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import('./pages/users/index.vue'),
    meta: {
      role: 'admin',
      title: 'Utilisateurs',
      dashboard: true
    },
    sidebar: {
      text: 'Utilisateurs',
      icon: 'users',
      ignoreChildren: true
    },
    children: [
      {
        path: 'create',
        name: 'users.create',
        component: () => import('./pages/users/create.vue'),
        meta:{
          dashboard: true
        }
      },
      {
        path: ':userId/edit',
        name: 'users.edit',
        component: () => import('./pages/users/edit.vue'),
        meta:{
          dashboard: true
        }
      }
    ]
  },
  {
    path: '/products',
    name: 'productList',
    component: () => import('../FNM/pages/product/listeProduct.vue'),
    sidebar: {
      text: 'Produits',
      icon: 'produit'
    },
    meta: {
      dashboard: true,
      isSFD: true
    }
  },
  {
    path: '/logs',
    name: 'monitoring.general.logs',
    component: () => import('./pages/logs/general.vue'),
    meta: {
      role: 'admin',
      dashboard: true
    },
    sidebar: {
      icon: 'logs',
      text: 'Journaux'
    }
  }
]

export const SFDRoutes: any[] = [
  {
    path: '/dashboard/:productId',
    name: 'dashboard',
    component: () => import('./pages/index.vue'),
    meta: {
      title: 'Tableau de bord'
    },
    sidebar: {
      icon: 'dashboard',
      text: 'Tableau de bord'
    }
  },

  {
    path: '/dashboard/:productId/dispatchings',
    component: RouterViewHolder,
    sidebar: {
      text: 'Opérations'
    },
    children: [
      {
        path: '',
        name: 'dispatchings.index',
        component: () => import('./pages/dispatchings/index.vue'),
        meta: {
          title: 'Liste des dispatchings',
          role: 'admin|ceo|auditor|agencyhead'
        },
        sidebar: {
          icon: 'list-outline',
          text: 'Liste des dispatchings'
        }
      },
      {
        path: 'create',
        name: 'dispatchings.create',
        component: () =>
          import('./pages/dispatchings/create/upload-file/index.vue'),
        meta: {
          title: 'Nouveau dispatching',
          role: 'agencyhead|accounting'
        },

        sidebar: {
          icon: 'money-dispatch',
          text: 'Nouveau dispatching'
        }
      },
      {
        path: 'create/summary',
        name: 'dispatchings.create.summary',
        component: () => import('./pages/dispatchings/create/summary.vue'),
        meta: {
          title: 'Validation du fichier de dispatching',
          role: 'agencyhead|accounting|ceo|auditor'
        }
      },
      {
        path: ':dispatchingId/show',
        name: 'dispatchings.show',
        component: () => import('./pages/dispatchings/list.vue'),
        //component: () => import('./pages/dispatchings/show.vue'),
        meta: {
          title: 'Détails du dispatching'
        }
      },
      {
        path: ':dispatchingId/summary',
        name: 'dispatchings.summary',
        component: () => import('./pages/dispatchings/summary.vue'),
        meta: {
          title: 'Détails du dispatching'
        }
      },
      {
        path: 'fund-reimbursement-account',
        name: 'fund_reimbursement_account',
        component: () => import('./pages/fund-reimbursement-account.vue'),
        meta: {
          role: 'accounting'
        },
        sidebar: {
          icon: 'money-out',
          iconSize: 6,
          text: 'Alimenter le compte de remboursement'
        }
      },
      {
        path: 'refund-fnm',
        name: 'dispatchings.refund-fnm',
        component: () => import('./pages/refund-fnm.vue'),
        meta: {
          role: 'accounting'
        },
        sidebar: {
          icon: 'refund',
          text: 'Rembourser le FNM'
        }
      },
      {
        path: 'kyc',
        name: 'check',
        component: () => import('./pages/check.vue'),
        sidebar: {
          icon: 'kyc',
          text: 'KYC Bénéficiaire'
        }
      },
      {
        path: 'verification',
        name: 'Vérification',
        component: () =>
          import('../FNM/pages/verification/searchTransaction.vue'),
        sidebar: {
          icon: 'verif',
          text: 'Vérification'
        }
      }
    ]
  },

  {
    path: '/ClientFiche',
    name: 'FicheClient',
    component: () => import('../FNM/pages/ficheclient/searchClient.vue'),
    sidebar: {
      icon: 'fc',
      text: 'Fiche Client'
    }
  },

  {
    path: '/ClientFiche/:id',
    name: 'DetailsFiche',
    component: () => import('../FNM/pages/ficheclient/detailsFiche.vue')
  },

  {
    path: '/ClientFiche/:id/details',
    name: 'credit.details',
    component: () => import('../FNM/pages/ficheclient/detailcredit.vue')
  },

  {
    path: '/dashboard/:productId',
    component: RouterViewHolder,
    sidebar: {
      text: 'Comptes'
    },
    children: [
      {
        path: 'provisions',
        component: () => import('./pages/finances/disbursments/index.vue'),
        meta: {
          title: 'Approvisionnement'
        },
        sidebar: {
          icon: 'balance',
          text: 'Approvisionnement',
          ignoreChildren: true
        },
        children: [
          {
            path: '',
            name: 'provisions',
            component: () =>
              import('./pages/finances/disbursments/provisions.vue')
          },
          {
            path: 'disbursements',
            name: 'provisions.disbursements',
            component: () =>
              import('./pages/finances/disbursments/disbursements.vue')
          }
        ]
      },

      {
        path: 'refunds',
        name: 'finances.refunds',
        component: () => import('./pages/finances/refunds/index.vue'),
        meta: {
          breadCrumb: 'Remboursements',
          title: 'Remboursements'
        },
        sidebar: {
          icon: 'money-change',
          text: 'Remboursements',
          ignoreChildren: true
        }
      },
      {
        path: 'refunds/:dispatchingId',
        name: 'finances.refunds.group',
        component: () => import('./pages/finances/refunds/by-group.vue'),
        meta: {
          breadCrumb: 'Remboursements'
        }
      },
      {
        path: 'refunds/details/:beneficiaryGroupId',
        name: 'finances.refunds.details',
        component: () => import('./pages/finances/refunds/by-person.vue')
      },
      {
        path: 'refunds-consignations',
        name: 'finances.refunds.consignations',
        component: () => import('./pages/finances/refunds/manual/index.vue')
      },
      {
        path: 'refunds-consignations/search-creditLine',
        name: 'finances.refunds.consignations.searchCreditLine',
        component: () =>
          import('./pages/finances/refunds/manual/searchLine.vue')
      },
      {
        path: 'refunds-consignations/:lineId/list-dispatchings',
        name: 'finances.refunds.consignations.listDispatchings',
        component: () =>
          import('./pages/finances/refunds/manual/listDispatchings.vue')
      },
      {
        path: 'refunds-consignations/:dispatchingId/dispatching',
        name: 'finances.refunds.consignations.dispatchingView',
        component: () =>
          import('./pages/finances/refunds/manual/dispatchingView.vue')
      },
      {
        path: 'refunds-consignations/:dispatchingId/charge-reimbursement-file',
        name: 'finances.refunds.consignations.chargeReimbFile',
        component: () =>
          import('./pages/finances/refunds/manual/chargeReimbFile.vue')
      },
      {
        path:
          'refunds-consignations/:reimbursementFileId/reimbursement-file-validation',
        name: 'finances.refunds.consignations.validationReimbFile',
        component: () =>
          import('./pages/finances/refunds/manual/validationReimbFile.vue')
      },
      {
        path: 'interests',
        name: 'finances.interests',
        component: () => import('./pages/finances/interests/index.vue'),
        meta: {
          title: 'Interêts'
        },
        sidebar: {
          icon: 'money-stats',
          text: 'Interêts'
        }
      },
      {
        path: 'interests/:interestId/details',
        name: 'interests.details',
        component: () => import('./pages/finances/interests/details.vue')
      }
    ]
  },

  {
    path: '/dashboard/:productId/monitoring',
    component: () => import('./layouts/monitoring.vue'),
    sidebar: {},
    children: [
      {
        path: 'reports',
        name: 'monitoring.reports',
        component: () => import('./pages/reports/index.vue')
      },
      {
        path: 'reports/operations',
        name: 'monitoring.reports.operations',
        component: () => import('./pages/reports/periodic.vue'),
        sidebar: {
          separator: true,
          icon: 'logs',
          text: 'Rapports'
        }
      },
      {
        path: 'logs',
        name: 'monitoring.logs',
        component: () => import('./pages/logs/index.vue'),
        meta: {
          role: 'admin'
        },
        sidebar: {
          icon: 'logs',
          text: 'Journaux'
        }
      },
      {
        path: 'reports/periodic',
        name: 'monitoring.reports.periodic',
        component: () => import('./pages/reports/periodic.vue')
      }
    ]
  },

  {
    path: '/dashboard/:productId/help',
    name: 'help',
    component: () => import('./pages/index.vue'),
    sidebar: {
      text: 'Aide'
    }
  }
]
