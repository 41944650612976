import { Model } from '@vuex-orm/core'

export default class DispatchingFile extends Model {
  static entity = 'dispatching_files'

  id!: string
  nbBeneficiaries!: number

  static fields() {
    return {
      id: this.string(''),
      mtnAmount: this.number(0),
      moovAmount: this.number(0),
      totalAmount: this.number(0),
      nbBeneficiaries: this.number(0)
    }
  }

  static apiConfig = {
    actions: {
      create(
        this: any,
        data: {
          file: File
          creditLineId: string
          mapping: Record<string, number>
        }
      ): any {
        const formData = new FormData()

        Object.keys(data.mapping).forEach((key: string) => {
          formData.append(`mapping[${key}]`, String(data.mapping[key]))
        })

        formData.append('creditLineId', data.creditLineId)
        formData.append('file', data.file)

        return this.post('/dispatching-files', formData)
      },

      report(this: any, fileId: string) {
        return this.get(`/dispatching-files/${fileId}/report`, { save: false })
      }
    }
  }
}
