export interface IAuthState {
  accessToken: string | null
  twoFA: boolean
  userId: string | null
}

export const AuthState = (): IAuthState => {
  return {
    accessToken: null,
    twoFA: false,
    userId: null
  }
}
