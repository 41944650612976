export interface BeneficiaryCounts {
  id: string
  name: string
  beneficiaries: number
}
export interface IRootState {
  beneficiaryCountsBySfd: Record<string, BeneficiaryCounts>
  beneficiaryCountsBySfdIds: string[]
}

export const RootState = (): IRootState => {
  return {
    beneficiaryCountsBySfd: {},
    beneficiaryCountsBySfdIds: []
  }
}
