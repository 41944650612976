import { Fields, Model } from '@vuex-orm/core'
import Product from './Product'
import { Request } from '@vuex-orm/plugin-axios'

export enum AccountType {
  DISBURSEMENT = 'disbursement',
  FEES = 'fees',
  REFUND = 'refund',
  INTEREST = 'interest',
  RESERVE = 'reserve',
  EXTERNALOUTGOING = 'externaloutgoing',
  EXTERNALINCOMING = 'externalincoming'
  //SFDCARD = 'sfdcard'
}

export enum AccountState {
  ACTIVE = 'active',
  SLEEPER = 'sleeper'
}

export default class Account extends Model {
  static entity = 'Account'

  id!: string
  state!: AccountState
  owner!: string
  type!: AccountType
  productId!: string
  key!: string
  name!: string
  number!: string
  balance!: number
  otm!: string
  product!: Product

  static fields(): Fields {
    return {
      id: this.string(null),
      state: this.string(null),
      owner: this.string(''),
      type: this.string(null),
      productId: this.attr(null),
      key: this.string(''),
      name: this.string(''),
      number: this.string(''),
      balance: this.number(0),
      otm: this.string(''),
      product: this.belongsTo(Product, 'productId')
    }
  }

  static apiConfig = {
    actions: {
      fetchAll(this: Request) {
        return this.get('accounts')
      },
      search(this: Request, search: string) {
        if (search)
          return this.get('accounts/all', {
            persistBy: 'create',
            params: {
              search: search
            }
          })
        return this.get('accounts/all', { persistBy: 'create' })
      },
      creditDistributionAccount(this: Request, data: any) {
        let formatData = {}

        console.log('this is data', formatData)

        if (Object.keys(data).length != 0) {
          formatData = {
            accountKey: data.accountKey,
            amount: parseInt(data.amount),
            productId: data.productId,
            otm: data.otm,
            accountName: data.accountName
          }
        }

        return this.post('accounts/credit-distribution', formatData, {
          params: {
            otp: data.otp
          }
        })
      },

      withdrawlRefundAccount(this: Request, data: any) {
        const formData = new FormData()
        formData.append('file', data.file)
        formData.append('accountKey', data.accountKey)
        formData.append('amount', data.amount)
        formData.append('productId', data.productId)
        formData.append('otm', data.otm)
        formData.append('accountName', data.accountName)

        return this.post('accounts/withdrawl-from-refund', formData, {
          params: {
            otp: data.otp
          }
        })
      },

      creditReserveAccount(this: Request, data: any) {
        const formData = new FormData()
        formData.append('file', data.file)
        formData.append('amount', data.amount)
        formData.append('productId', data.productId)
        formData.append('otm', data.otm)

        return this.post('accounts/credit-reserve', formData, {
          params: {
            otp: data.otp
          }
        })
      },

      accountBalance(this: Request, accountKey: string) {
        return this.get(`accounts/balance/${accountKey}`, {
          save: false
        })
      },

      specialAccounts(this: Request, productId?: string) {
        const params: any = {}
        if (productId) params.productId = productId
        return this.get('accounts/special-accounts', {
          params,
          save: false
        })
      },

      download(this: Request, accountKey: string) {
        return this.get(`accounts/rcb/${accountKey}`, {
          params: {
            format: 'excel'
          }
        })
      }
    }
  }
}
