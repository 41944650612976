import Product from '@/store/models/Product'
export interface ProductActionInterface {
  setCurrentProduct(context: any, product: Product): void
}

export const ProductAction: ProductActionInterface = {
  setCurrentProduct({ commit }, data) {
    commit('SET_CURRENT_PRODUCT', data)
  }
}
