



















import Vue from 'vue'
import { mapGetters } from 'vuex'
import { SFDRoutes, ProductRoutes } from '@/apps/SFD/routes'
import DashboardLayout from './dashboard.vue'
import Organization from '../store/models/Organization'

export default Vue.extend({
  components: {
    DashboardLayout
  },

  data() {
    return {
      routes: SFDRoutes
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),
    getRoutesListe() {
      if (this.$route) {
        if (this.$route.meta?.dashboard) {
          return ProductRoutes
        }
      }
      return SFDRoutes
    },

    organization(this: any) {
      return Organization.find(this.user.organizationId)
    }
  }
})
