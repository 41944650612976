import { IDispatchingFileState } from './state'

export enum Types {
  UPDATE_NEW_DISPATCHING_FILE = 'UPDATE_NEW_DISPATCHING_FILE',
  UPDATE_NEW_DISPATCHING_FILE_MAPPING = 'UPDATE_NEW_DISPATCHING_FILE_MAPPING'
}

export interface IDispatchingFileMutations {
  [Types.UPDATE_NEW_DISPATCHING_FILE](
    state: IDispatchingFileState,
    values: {
      file: File
      headers: string[]
      items: any[][]
      creditLineId: string
    }
  ): void

  [Types.UPDATE_NEW_DISPATCHING_FILE_MAPPING](
    state: IDispatchingFileState,
    mapping: Record<string, number>
  ): void
}

export const DispatchingFileMutations: IDispatchingFileMutations = {
  [Types.UPDATE_NEW_DISPATCHING_FILE](
    state,
    { file, headers, items, creditLineId }
  ): void {
    state.newDispatchingFile.file = file
    state.newDispatchingFile.headers = headers
    state.newDispatchingFile.items = items
    state.newDispatchingFile.creditLineId = creditLineId
  },

  [Types.UPDATE_NEW_DISPATCHING_FILE_MAPPING](state, mapping): void {
    state.newDispatchingFile.mapping = mapping
  }
}
