























































































































































































































import Vue from 'vue'
import valueAt from 'lodash/get'
import VButton from './VButton.vue'
import VInput from './VInput.vue'

export default Vue.extend({
  components: {
    VButton,
    VInput
  },

  props: {
    title: String,

    showHover: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },

    items: {
      type: Array,
      default: () => []
    },

    addButtonText: {
      type: String,
      default: 'Ajouter'
    },

    noDataText: {
      type: String,
      default: 'Aucune donnée disponible'
    },

    loading: Boolean,
    loadingText: {
      type: String,
      default: 'Chargement des données'
    },

    height: [String, Number],

    sortBy: String,
    sortDesc: Boolean,
    flat: Boolean,
    hideAddAction: Boolean,
    hideSearchInput: Boolean,
    hideTableHeader: Boolean,

    rowClass: {
      type: Function,
      default: () => ''
    },

    expandable: Boolean,
    paginated: Boolean,
    itemPerPage: Number,
    bgWhitePaginated: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentElement: '',
      search: '',
      sort: {
        field: '',
        desc: false
      },
      perPage: this.itemPerPage || 10,
      page: 1,
      rowsDetailsExpanded: {}
    }
  },

  computed: {
    total(): number {
      return this.items.length
    },

    pages(): number {
      return Math.ceil(this.total / this.perPage)
    },

    bodyStyle(this: any) {
      const style: Record<string, any> = {}

      if (this.height) {
        style.height = `${this.height}px`
      }

      return style
    },

    tableHeaderStyle(this: any) {
      const style: Record<string, any> = {}

      if (this.height) {
        style.position = 'sticky'
        style.top = 0
      }

      return style
    },

    formattedColumns(this: any) {
      return this.columns.map((col: any) => ({
        sortable: true,
        searchable: false,
        align: 'left',
        sortFn: () => '',
        ...col
      }))
    },

    filteredItems(this: any) {
      if (!this.search || this.searchableFields.length === 0) {
        return this.items
      }

      const searchReg = new RegExp(this.search, 'gi')

      return this.items.filter((item: any) =>
        this.searchableFields.some((field: string) =>
          searchReg.test(valueAt(item, field))
        )
      )
    },

    sortedItems(this: any) {
      if (!this.sort.field) {
        return this.filteredItems
      }

      return this.filteredItems.concat().sort((itemA: any, itemB: any) => {
        const valueA = valueAt(itemA, this.sort.field)
        const valueB = valueAt(itemB, this.sort.field)

        if (valueA < valueB) {
          return this.sort.desc ? 1 : -1
        } else if (valueA > valueB) {
          return this.sort.desc ? -1 : 1
        } else {
          return 0
        }
      })
    },

    visibleItems(this: any) {
      if (!this.paginated) {
        return this.sortedItems
      }

      const start = (this.page - 1) * this.perPage
      return this.sortedItems.slice(start, start + this.perPage)
    },

    searchableFields(this: any) {
      return this.columns
        .filter((col: any) => col.searchable)
        .map((col: any) => col.field)
    }
  },

  watch: {
    sortBy(this: any, field) {
      this.sort.field = field
    },

    sortDesc(this: any, desc) {
      this.sort.desc = desc
    }
  },

  created(this: any) {
    this.sort.field = this.sortBy
    this.sort.desc = !!this.sortDesc

    if (!this.sort.field) {
      this.sort.field = this.formattedColumns.find((col: any) => col.sortable)
    }
  },

  methods: {
    getItemField(item: any, field: string) {
      return valueAt(item, field)
    },

    onAddClicked() {
      this.$emit('click:add')
    },

    onPrevClicked() {
      if (this.page > 1) {
        this.page--
      }
    },

    onNextClicked() {
      if (this.page < this.pages) {
        this.page++
      }
    },

    isRowExpanded(this: any, index: number) {
      return !!this.rowsDetailsExpanded[index]
    },

    toggleRowDetails(this: any, index: number) {
      this.$set(this.rowsDetailsExpanded, index, !this.isRowExpanded(index))
    },

    onColumnClicked(this: any, column: any) {
      if (!column.sortable) {
        return
      }

      this.sort.field = column.field
      this.sort.desc = !this.sort.desc

      this.$emit('update:sort-by', this.sort.field)
      this.$emit('update:sort-desc', this.sort.desc)
    }
  }
})
