import { Module } from 'vuex'
import { ProductState, ProductStateInterface } from './state'
import { IRootState } from '../root'
import { ProductGetters } from './getters'
import { ProductAction } from './actions'
import { ProductMutations } from './mutations'

export const ProductModule: Module<ProductStateInterface, IRootState> = {
  namespaced: true,

  state: {
    ...ProductState()
  },

  getters: {
    ...ProductGetters
  },

  actions: {
    ...ProductAction
  },

  mutations: {
    ...ProductMutations
  }
}
