import { IAuthState } from './state'

export const Types = {
  USER_ID: 'USER_ID' as const,
  ACCESS_TOKEN: 'ACCESS_TOKEN' as const,
  TWO_FA: 'TWO_FA' as const
}

export interface IAuthMutations {
  [Types.USER_ID](state: IAuthState, userId: string): void
  [Types.ACCESS_TOKEN](state: IAuthState, accessToken: string): void
  [Types.TWO_FA](state: IAuthState, verified: boolean): void
}

export const AuthMutations: IAuthMutations = {
  [Types.USER_ID](state: IAuthState, userId: string) {
    state.userId = userId
  },

  [Types.ACCESS_TOKEN](state: IAuthState, accessToken: string) {
    state.accessToken = accessToken
  },

  [Types.TWO_FA](state: IAuthState, verified: boolean) {
    state.twoFA = verified
  }
}
