import { Model } from '@vuex-orm/core'

export default class ReimbursementFile extends Model {
  static entity = 'reimbursement_files'

  id!: string
  nbBeneficiaries!: number

  static fields() {
    return {
      id: this.string(''),
      nbBeneficiaries: this.number(0)
    }
  }

  static apiConfig = {
    actions: {
      create(
        this: any,
        data: {
          file: File
          dispatchingId: string
          mapping: Record<string, number>
        }
      ): any {
        const formData = new FormData()
        data.mapping = {
          group: 0,
          name: 1,
          phone: 2,
          amount: 3,
          date: 4,
          amountDue: 5
        }

        Object.keys(data.mapping).forEach((key: string) => {
          formData.append(`mapping[${key}]`, String(data.mapping[key]))
        })

        formData.append('dispatchingId', data.dispatchingId)
        formData.append('file', data.file)

        return this.post('/reimbursement-file/validate', formData)
      },

      fetchOne(this: any, id: string) {
        return this.get(`/reimbursement-file/one/${id}/`)
      },

      report(this: any, fileId: string) {
        return this.get(`/reimbursement-file/${fileId}/report`, {
          save: false
        })
      },

      launch(this: any, fileId: string) {
        return this.post(`/reimbursement-file/launch/${fileId}`, {
          save: false
        })
      }
    }
  }
}
