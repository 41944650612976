import { Fields, Model } from '@vuex-orm/core'
import Product from './Product'
import { Request } from '@vuex-orm/plugin-axios'
import store from '..'

export enum KkiapayAccountType {
  DEFAULT = 'default', // Compte de décaissement
  REFUND = 'refund' // Compte encaissement'
}

export default class KkiapayAccount extends Model {
  static entity = 'KkiapayAccount'

  id!: string
  productId!: string
  type!: KkiapayAccountType
  mtnBalance!: number
  moovBalance!: number
  balance!: number
  organizationId!: string
  product!: Product

  static fields(): Fields {
    return {
      id: this.string(null),
      organizationId: this.string(''),
      type: this.string(''),
      productId: this.attr(null),
      mtnBalance: this.number(0),
      moovBalance: this.number(0),
      balance: this.number(0),
      product: this.belongsTo(Product, 'productId')
    }
  }

  static apiConfig = {
    actions: {
      fetchAll(this: Request) {
        const loggedInOrganizationId = store.getters['auth/user'].organizationId
        return this.get('kkiapay-accounts', {
          params: {
            organizationId: loggedInOrganizationId
          }
        })
      }
    }
  }
}
