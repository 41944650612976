import router from '@/router'
import { Fields, Model } from '@vuex-orm/core'
import store from '..'
import Product from './Product'

import User from './User'

export default class Logs extends Model {
  static entity = 'logs'

  id!: string
  action!: string
  description!: string
  createdAt!: string
  subject!: any
  organizationId!: string
  productId!: string
  causerId!: string
  causer!: User

  static fields(): Fields {
    return {
      id: this.string(''),
      action: this.string(''),
      description: this.string(''),
      createdAt: this.string(''),
      subject: this.string({}),
      organizationId: this.string(''),
      productId: this.string(''),

      causerId: this.string(''),
      causer: this.belongsTo(User, 'causerId')
    }
  }

  get text(): string {
    return this.description || this.action
  }

  static apiConfig = {
    actions: {
      fetch(this: any): any {
        const params: any = { sort: '-createdAt' }

        const currentProduct: Product =
          store.getters['product/getCurrentProduct']
        const currentRoute = router.currentRoute
        if (!currentRoute.meta?.dashboard && currentProduct.id) {
          params.productId = currentProduct.id
        } else {
          params.productId = null
        }
        return this.get('/logs', { params: params, persistBy: 'create' })
      }
    }
  }
}
