export interface DispatchingFileMapping {
  sfd: number
  name: number
  gender: number
  state: number
  commune: number
  district: number
  town: number
}

export interface NewDispatchingFile {
  file: File | null
  headers: string[]
  items: any[][]
  creditLineId: string
  mapping: Record<string, number>
}

export interface IDispatchingFileState {
  newDispatchingFile: NewDispatchingFile
}

export const DispatchingFileState = (): IDispatchingFileState => ({
  newDispatchingFile: {
    file: null,
    headers: [],
    items: [],
    creditLineId: '',
    mapping: {}
  }
})
