import { IRootState, BeneficiaryCounts } from './state'

export enum Types {
  ADD_BENEFICIARY_COUNTS = 'ADD_BENEFICIARY_COUNTS'
}

export interface IRootMutations {
  [Types.ADD_BENEFICIARY_COUNTS](
    state: IRootState,
    data: BeneficiaryCounts[]
  ): void
}

export const RootMutations: IRootMutations = {
  [Types.ADD_BENEFICIARY_COUNTS](state, data) {
    data.forEach((item: BeneficiaryCounts) => {
      state.beneficiaryCountsBySfd[item.id] = item
    })

    state.beneficiaryCountsBySfdIds = Object.keys(state.beneficiaryCountsBySfd)
  }
}
