import Vue from 'vue'
import Breadstick from 'breadstick'
import VNotification from '@/components/VNotification.vue'

const breadstick = new Breadstick()

const notify = function({
  variant = 'info',
  message = '',
  title = '',
  position = 'top-right',
  duration = 3000
} = {}) {
  const breadstickOptions = {
    duration,
    position
  }

  breadstick.notify(({ h }: any) => {
    return h('v-theme-provider', [
      h(VNotification, {
        props: {
          variant,
          message,
          title
        }
      })
    ])
  }, breadstickOptions)
}

const createNotifyFn = (variant: string) => (
  message: string | Record<string, unknown> = {},
  options = {}
) => {
  if (typeof message === 'string') {
    options = { message, ...options }
  } else {
    options = message
  }

  return notify({ ...options, variant })
}

notify.success = createNotifyFn('success')
notify.warning = createNotifyFn('warning')
notify.danger = createNotifyFn('error')
notify.error = createNotifyFn('error')
notify.info = createNotifyFn('info')

Vue.prototype.$notify = notify
