










































































import Vue from 'vue'
import { mask } from 'vue-the-mask'
import { ValidationProvider } from 'vee-validate'

const ICON_SIZES: Record<string, number> = {
  small: 4,
  medium: 6,
  large: 8
}

const MARGINS: Record<string, number> = {
  small: 2,
  medium: 3,
  large: 4
}

const TEXT_SIZES: Record<string, string> = {
  small: 'sm',
  medium: 'md',
  large: '2xl'
}

export default Vue.extend({
  inheritAttrs: false,

  components: {
    ValidationProvider
  },

  directives: { mask },

  props: {
    validation: {
      type: [String, Object],
      default: ''
    },

    validationMode: {
      type: String,
      default: 'aggressive'
    },

    value: {
      type: [String, Number, Object],
      default: null
    },

    inputClass: String,

    mask: String,

    label: String,
    leftIcon: String,
    rightIcon: String,
    rightIconWithAction: String,
    small: Boolean,
    large: Boolean,

    textarea: Boolean,
    number: Boolean,
    rightIconAction: {
      type: Function,
      default: function(attrs: any) {
        attrs.type == 'password'
          ? (attrs.type = 'text')
          : (attrs.type = 'password')
      }
    }
  },

  data() {
    return {
      model: this.value,
      hasFocus: false
    }
  },

  computed: {
    tag() {
      return this.textarea ? 'textarea' : 'input'
    },

    listeners(this: any) {
      return {
        ...this.$listeners,
        input: (event: any) => {
          this.$emit('input', event.target.value)
        },
        focus: (event: any) => {
          this.hasFocus = true
          this.$emit('focus', event)
        },
        blur: (event: any) => {
          this.hasFocus = false
          this.$emit('blur', event)
        }
      }
    },

    size(): string {
      return this.small ? 'small' : this.large ? 'large' : 'medium'
    },

    leftIconWrapperClasses() {
      return `absolute z-10 pl-${MARGINS[this.size as string]}`
    },

    rightIconWrapperClasses() {
      return `absolute z-10 right-0 pr-${MARGINS[this.size as string]}`
    }
  },

  watch: {
    value(this: any, newValue) {
      if (newValue !== this.model) {
        this.model = newValue
      }
    }
  },

  methods: {
    iconClasses(this: any, validator: any) {
      const classes = [
        `fill-current w-${ICON_SIZES[this.size]} h-${ICON_SIZES[this.size]}`
      ]

      if (validator.failed) {
        if (this.hasFocus) {
          classes.push('text-red-300')
        } else {
          classes.push('text-red-200')
        }
      } else {
        if (this.hasFocus) {
          classes.push('text-primary')
        } else {
          classes.push('text-gray-500')
        }
      }

      return classes
    },

    inputClasses(this: any, validator: any) {
      const classes = [
        this.inputClass,
        'placeholder-gray-500 text-gray-700',
        'relative bg-white bg-white rounded border w-full',
        'outline-none focus:outline-none',
        {
          'px-3 py-3 text-md': !this.small && !this.large
        }
      ]

      if (validator.failed) {
        classes.push('border-red-200 focus:border-red-300 focus:bg-red-100')
      } else {
        classes.push('border-gray-400 focus:border-blue-300 focus:bg-blue-100')
      }

      classes.push(`text-${TEXT_SIZES[this.size]}`)
      classes.push(`py-${MARGINS[this.size]}`)

      let pl = MARGINS[this.size]
      let pr = MARGINS[this.size]

      if (this.leftIcon) {
        pl += pl + ICON_SIZES[this.size]
      }

      if (this.rightIcon) {
        pr += pr + ICON_SIZES[this.size]
      }

      classes.push(`pl-${pl}`)
      classes.push(`pr-${pr}`)

      return classes
    }
  }
})
