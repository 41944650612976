import Vue from 'vue'
import moment from 'moment'
import { Deadline, ICreditLineProvisionState, NewProvision } from './state'
import Product from '@/store/models/Product'

export enum Types {
  UPDATE_NEW_PROVISION_INFO = 'UPDATE_NEW_PROVISION_INFO',
  UPDATE_NEW_PROVISION_AMOUNT = 'UPDATE_NEW_PROVISION_AMOUNT',
  GENERATE_NEW_PROVISION_DEADLINES = 'GENERATE_NEW_PROVISION_DEADLINES',
  EDIT_NEW_PROVISION_DEADLINE = 'EDIT_NEW_PROVISION_DEADLINE',
  DELETE_NEW_PROVISION_DEADLINE = 'DELETE_NEW_PROVISION_DEADLINE'
}
interface GenerateWithProduct {
  otm: string
  product: Product
}
export interface ICreditLineProvisionMutations {
  [Types.UPDATE_NEW_PROVISION_INFO](
    state: ICreditLineProvisionState,
    values: NewProvision
  ): void

  [Types.GENERATE_NEW_PROVISION_DEADLINES](
    state: ICreditLineProvisionState,
    data: GenerateWithProduct
  ): void

  [Types.UPDATE_NEW_PROVISION_AMOUNT](
    state: ICreditLineProvisionState,
    data: { otm: string; amount: number }
  ): void

  [Types.EDIT_NEW_PROVISION_DEADLINE](
    state: ICreditLineProvisionState,
    data: { index: number; data: Deadline }
  ): void

  [Types.DELETE_NEW_PROVISION_DEADLINE](
    state: ICreditLineProvisionState,
    data: { index: number; otm: string }
  ): void
}

export const CreditLineProvisionMutations: ICreditLineProvisionMutations = {
  [Types.UPDATE_NEW_PROVISION_INFO](
    state,
    {
      reference,
      organizationId,
      name,
      durationInMonth,
      annualRate,
      deferred,
      graceDelay,
      effectDate,
      modeCalcul
    }
  ) {
    state.newProvision.name = name
    state.newProvision.reference = reference
    state.newProvision.deferred = deferred
    state.newProvision.graceDelay = graceDelay
    state.newProvision.effectDate = effectDate
    state.newProvision.annualRate = annualRate
    state.newProvision.organizationId = organizationId
    state.newProvision.durationInMonth = durationInMonth
    state.newProvision.modeCalcul = modeCalcul
  },

  [Types.UPDATE_NEW_PROVISION_AMOUNT](state, { otm, amount }) {
    if (otm === 'mtn') {
      state.newProvision.mtnAmount = amount
    } else {
      state.newProvision.moovAmount = amount
    }
  },

  [Types.GENERATE_NEW_PROVISION_DEADLINES](state, data) {
    const product = data.product
    const otm = data.otm
    const totalAmount =
      otm === 'mtn'
        ? state.newProvision.mtnAmount
        : state.newProvision.moovAmount

    const periodicity = product.reimbursementDetails.sfdPaymentPeriodicity

    const nbEcheance = state.newProvision.durationInMonth / periodicity

    const deadlines =
      otm === 'mtn'
        ? state.newProvision.mtnDeadlines
        : state.newProvision.moovDeadlines

    if (totalAmount === 0) {
      if (otm === 'mtn') {
        state.newProvision.mtnDeadlines = []
      } else {
        state.newProvision.moovDeadlines = []
      }

      return
    }

    const sfdInterestRate = product.reimbursementDetails.sfdInterestRate || 0

    const totalInterestAmount = Math.ceil((totalAmount * sfdInterestRate) / 100)

    const effectDate = state.newProvision.effectDate

    let delay = 0
    let defer = 0
    let deadlineAmount = 0
    let deadlineInterestAmount = 0
    if (
      state.newProvision.graceDelay > 0 &&
      state.newProvision.deferred === 0
    ) {
      delay = state.newProvision.graceDelay / periodicity
      deadlineAmount = Math.ceil(totalAmount / (nbEcheance - delay))
      deadlineInterestAmount = Math.ceil(
        (deadlineAmount * sfdInterestRate) / 100
      )
    } else if (
      state.newProvision.graceDelay === 0 &&
      state.newProvision.deferred > 0
    ) {
      defer = state.newProvision.deferred / periodicity
      deadlineAmount = Math.ceil(totalAmount / (nbEcheance - defer))
      deadlineInterestAmount = Math.ceil(totalInterestAmount / nbEcheance)
    } else if (
      state.newProvision.graceDelay > 0 &&
      state.newProvision.deferred > 0
    ) {
      delay = state.newProvision.graceDelay / periodicity
      defer = state.newProvision.deferred / periodicity
      deadlineAmount = Math.ceil(totalAmount / (nbEcheance - (delay + defer)))
      deadlineInterestAmount = Math.ceil(
        totalInterestAmount / (nbEcheance - delay)
      )
    } else {
      deadlineAmount = Math.ceil(totalAmount / nbEcheance)
      deadlineInterestAmount = Math.ceil(
        (deadlineAmount * sfdInterestRate) / 100
      )
    }

    // TODO: rewrite this shit
    for (let i = 0; i < nbEcheance; i++) {
      let amount = 0
      let interest = 0
      if (delay > 0 && defer === 0) {
        amount =
          i < delay
            ? 0
            : i < nbEcheance - 1
            ? deadlineAmount
            : totalAmount - deadlineAmount * (nbEcheance - (delay + 1))
        interest =
          i < delay
            ? 0
            : i < nbEcheance - 1
            ? deadlineInterestAmount
            : totalInterestAmount -
              deadlineInterestAmount * (nbEcheance - (delay + 1))
      } else if (delay === 0 && defer > 0) {
        amount =
          i < defer
            ? 0
            : i < nbEcheance - 1
            ? deadlineAmount
            : totalAmount - deadlineAmount * (nbEcheance - (defer + 1))
        interest =
          i < nbEcheance - 1
            ? deadlineInterestAmount
            : totalInterestAmount - deadlineInterestAmount * (nbEcheance - 1)
      } else if (delay > 0 && defer > 0) {
        amount =
          i < delay + defer
            ? 0
            : i < nbEcheance - 1
            ? deadlineAmount
            : totalAmount - deadlineAmount * (nbEcheance - (delay + defer + 1))
        interest =
          i < delay
            ? 0
            : i < nbEcheance - 1
            ? deadlineInterestAmount
            : totalInterestAmount -
              deadlineInterestAmount * (nbEcheance - (delay + 1))
      } else {
        amount =
          i < nbEcheance - 1
            ? deadlineAmount
            : totalAmount - deadlineAmount * (nbEcheance - 1)
        interest =
          i < nbEcheance - 1
            ? deadlineInterestAmount
            : totalInterestAmount - deadlineInterestAmount * (nbEcheance - 1)
      }

      /* let interest = 0
       if (state.newProvision.modeCalcul == 'degressif') {
        interest =
          i == 0
            ? (deadlineAmount * sfdInterestRate) / 100
            : ((((nbEcheance - i + 1) * deadlineAmount) / nbEcheance) *
                sfdInterestRate) /
              100
      } else
        interest =
          i < delay
            ? 0
            : i < nbEcheance - 1
            ? deadlineInterestAmount
            : totalInterestAmount -
              deadlineInterestAmount * (nbEcheance - (delay + 1))
      */
      //Calucul de l'intérêt pour une évolution dégressif

      //Fin

      if (i < deadlines.length) {
        Vue.set(
          otm === 'mtn'
            ? state.newProvision.mtnDeadlines
            : state.newProvision.moovDeadlines,
          i,
          {
            ...deadlines[i],
            capital: amount,
            interest,
            monthlyAmount: amount + interest
          }
        )
      } else {
        //
        ;(otm === 'mtn'
          ? state.newProvision.mtnDeadlines
          : state.newProvision.moovDeadlines
        ).push({
          otm,
          capital: amount,
          interest,
          monthlyAmount: amount + interest,
          date: moment(effectDate)
            .add(i * periodicity + 1, 'month')
            .toDate()
        })
      }
    }
  },

  [Types.EDIT_NEW_PROVISION_DEADLINE](state, { index, data }) {
    if (data.otm === 'mtn') {
      Vue.set(state.newProvision.mtnDeadlines, index, data)
      Vue.set(
        state.newProvision,
        'mtnAmount',
        state.newProvision.mtnDeadlines.reduce(
          (total, item) => total + item.capital,
          0
        )
      )
    } else {
      Vue.set(state.newProvision.moovDeadlines, index, data)
      Vue.set(
        state.newProvision,
        'moovAmount',
        state.newProvision.moovDeadlines.reduce(
          (total, item) => total + item.capital,
          0
        )
      )
    }
  },

  [Types.DELETE_NEW_PROVISION_DEADLINE](state, { index, otm }) {
    if (otm === 'mtn') {
      state.newProvision.mtnDeadlines.splice(index, 1)
    } else {
      state.newProvision.moovDeadlines.splice(index, 1)
    }
  }
}
