import { Model } from '@vuex-orm/core'
import { Request } from '@vuex-orm/plugin-axios'
import moment from 'moment'
import store from '..'

export enum TransactionStatus {
  ALL = '',
  CREATED = 'created',
  OTP_VALIDATED = 'otp_validated',
  PENDING = 'pending',
  SUCCESSEED = 'successeed',
  FAILED = 'failed'
}
export default class Transaction extends Model {
  static entity = 'transactions'

  otm!: string
  amount!: number
  createdAt!: string

  static fields() {
    return {
      id: this.string(''),
      otm: this.string(''),
      reference: this.string(''),
      amount: this.number(0),
      kkiapayTransactionId: this.string(''),
      type: this.string(''),
      status: this.string(''),
      source: this.string(''),
      destination: this.string(''),
      otmTransactionId: this.string(''),
      createdAt: this.string(''),
      organization: this.attr(''),
      initiator: this.string('')
    }
  }

  get getCreatedAt(): string {
    return moment(this.createdAt).format('DD/MM/YYYY, hh:mm')
  }

  static apiConfig = {
    actions: {
      fundFnmDefaultAccount(this: Request, amount: number, otm: string) {
        const product = store.getters['product/getCurrentProduct']
        return this.post('/transactions/fund-fnm', {
          amount,
          otm,
          productId: product.id
        })
      },

      validateTransaction(this: Request, transactionId: string, otp?: string) {
        const data = otp ? { otp } : {}
        return this.put(`/transactions/${transactionId}/execute`, data)
      },

      filter(
        this: Request,
        data: {
          search: string
          otm: string
          status: TransactionStatus
          from: string
          to: string
          organizationId: string
        }
      ) {
        Transaction.deleteAll()
        return this.get(
          `/transactions/searching/all-transactions?search=${data.search}&otm=${data.otm}&status=${data.status}&from=${data.from}&to=${data.to}&organizationId=${data.organizationId}`
        )
      }
    }
  }
}
