import { ProductStateInterface } from './state'
import Product from '@/store/models/Product'

export enum Types {
  SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT'
}

export interface ProductMutationsInterface {
  [Types.SET_CURRENT_PRODUCT](
    state: ProductStateInterface,
    product: Product
  ): void
}

export const ProductMutations: ProductMutationsInterface = {
  [Types.SET_CURRENT_PRODUCT](state, currentProduct) {
    state.currentProduct = currentProduct
  }
}
