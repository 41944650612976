











import Vue from 'vue'

const SIZES_MAP: Record<string, string> = {
  xs: '3',
  sm: '4',
  md: '6',
  lg: '8',
  xl: '10',
  '2xl': '12',
  '3xl': '16',
  '4xl': '20',
  '5xl': '24',
  '6xl': '32'
}

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    size: {
      type: [String, Number],
      default: 'md'
    },

    width: {
      type: [String, Number],
      default: '2'
    },

    speed: {
      type: [String, Number],
      default: 600
    }
  },

  computed: {
    theSize(this: any) {
      if (Object.keys(SIZES_MAP).includes(this.size)) {
        return SIZES_MAP[this.size]
      }
      return String(this.size)
    },

    animationSpeed() {
      return this.speed
    }
  }
})
