import api, { Api, ApiResponse } from '@/api'

const actions = {
  async createCategorie(store: any, data: any) {
    try {
      const response: ApiResponse = await (api as Api).createCategorie(data)
      if (response.status != 200) {
        throw new Error('Veuillez réesayer!')
      }

      console.log(response.data)
    } catch (error) {
      throw new Error('Veuillez réesayer!')
    }
  },

  async allBeneficiaries(store: any, data: any) {
    const response: ApiResponse = await (api as Api).allBeneficiarie(data)

    store.commit('SET_ALL_BENEFICIARIES', response.data)
    console.log(data, 'actions')

    console.log(response.data)
  },
  async detailsBeneficiaries(store: any, data: any) {
    const response: ApiResponse = await (api as Api).infoBeneficiarie(data)

    store.commit('SET_DETAILS_BENEFICIARIES', response.data)
    console.log(response.data, 'actions')

    console.log(response.data)
  },
  showDetails(store: any, data: any) {
    store.commit('SET_CURRENT_BENEFICIARIE', data)
    console.log(data, 'actions')
  }
}
export default actions
