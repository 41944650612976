import { Module } from 'vuex'
import { IRootState } from '../root'
import { IAuthState, AuthState } from './state'
import { AuthGetters } from './getters'
import { AuthActions } from './actions'
import { AuthMutations } from './mutations'

const AuthModule: Module<IAuthState, IRootState> = {
  namespaced: true,

  state: {
    ...AuthState()
  },

  getters: {
    ...AuthGetters
  },

  actions: {
    ...AuthActions
  },

  mutations: {
    ...AuthMutations
  }
}

export default AuthModule
