const mutations = {
  SET_BENEFICIARIES(state: any, data: any) {
    state.allBeneficiaries = data
    console.log(state.allBeneficiaries, 'mutations')
  },
  SET_ALL_BENEFICIARIES(state: any, data: any) {
    state.all = data
  },
  SET_DETAILS_BENEFICIARIES(state: any, data: any) {
    state.oneBeneficiarie = data
    console.log(state.oneBeneficiarie, 'mutations')
  },
  SET_CURRENT_BENEFICIARIE(state: any, data: any) {
    state.currentBeneficiarie = data
  }
}
export default mutations
