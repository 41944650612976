export interface Deadline {
  date: Date
  otm: string
  capital: number
  interest: number
  monthlyAmount: number
}

export interface NewProvision {
  name: string
  reference: string
  deferred: number
  graceDelay: number
  effectDate: string
  annualRate: number
  organizationId: string
  durationInMonth: number
  modeCalcul?: string
  mtnAmount: number
  moovAmount: number
  mtnDeadlines: Deadline[]
  moovDeadlines: Deadline[]
}

export interface ICreditLineProvisionState {
  newProvision: NewProvision
}

export const CreditLineProvisionState = (): ICreditLineProvisionState => ({
  newProvision: {
    name: '',
    reference: '',
    deferred: 0,
    graceDelay: 0,
    effectDate: '',
    annualRate: 0,
    organizationId: '',
    durationInMonth: 0,

    mtnAmount: 0,
    moovAmount: 0,
    mtnDeadlines: [],
    moovDeadlines: []
  }
})
